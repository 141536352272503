import Service from "core/service";
import type { AxiosError } from 'axios'
import axios from "axios";

export class UserService extends Service {

  insert(body: any, options: any): Promise<any> {
    try {
      return this.post("/User", body, options);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  update(params: any): Promise<any> {
    try {
      return this.updateUserById(params[0].UserId, params[0]);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  updateDetail(params: any): Promise<any> {
    try {
      return this.patch(`/user/${params.UserId}`, params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  updateUserById(id: any, user: any): Promise<any> {
    try {
      return this.patch(`/User/${id}`, user);

    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  updateStatus(params: any, newStatus: number) {
    try {
      if(params['Users'].length > 0){
        let arrPromise = [];
        for(let i = 0; i < params['Users'].length; i ++ ){
          const promise = this.updateStatusByUser(params.Users[i].UserId, params.Users[i]);
          arrPromise.push(promise);
        }
        return Promise.all(arrPromise);
      }
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }
  updateStatusByUser(id: any, user: any): Promise<any> {
    try {
      return this.patch(`/User/${id}`, user);

    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }
  
  select(selectProps: string, req: any | null): Promise<any> {
    try {
      let params: any = {
        $select: selectProps,
        $expand: "ApprovalGroupMembers($expand=ApprovalGroup), TimesheetHeaderUsers",
        '$orderby': 'CreatedOn desc'
      };

      if(req){
        if(req.filter){
          params["$filter"] = req.filter;
        }

        if (req.orderBy) {
          params["$orderby"] = req.orderBy;
        }
        
        params["$count"] = req.count ? req.count : true;
        params["$top"] = req.pageSize ? req.pageSize : 100;
        params["$skip"] = req.pageSize * (req.currentPage ? req.currentPage - 1 : 0);
        
      } else {
        params["$count"] = true;
        params["$top"] = 1;
        params["$skip"] = 0;
      }
      const response = this.get("/User", params);
      return response;

    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  selectUserById(id: number, expand?: string) {
    let params: any = {};
    if (expand) {
      params["$expand"] = expand;
    }
    return this.get(`/User(${id})`, params);
  }


  selectByUserId(selectProps: string, req: any | null): Promise<any> {
    try {

      let params: any = {
        $select: selectProps,
      };

      if(req){
        if(req.filter){
          params["$filter"] = req.filter;

        }
      }
      const response = this.get("/User", params);
      return response;

    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  isExistEmail(email: any | null): Promise<any> {
    try {

      let params: any = {
        $filter: email
      };

      const response = this.get("/User", params);
      return response;

    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  deleteUsers(params: any) {
    try {
      if(params['Users'].length > 0){
        let arrPromise = [];
        for(let i = 0; i < params['Users'].length; i ++ ){
          const promise = this.deleteUserById(params['Users'][i].UserId);
          arrPromise.push(promise);
        }
        return Promise.all(arrPromise);
      }

    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  deleteUserById(id: any): Promise<any> {
    try {
      return this.delete(`/User/${id}`);

    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  appovalGroupList(selectProps: string, filter: string | null, limit: number | null): Promise<any> {
    try {
      let params: any = {
        $select: selectProps,
      };
    
      return this.get("/approvalGroup", params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
  
  }

  dropDownDivisionPayLocation(selectProps: string, filter: string | null, active?:number): Promise<any> {
    let params: any = {
      $select: selectProps,
      "$filter": active == -1 ? '' : active == 1|| active == undefined ? "(Active eq true)" : "(Active eq false)"
    };
    if (filter) {
      params["$filter"] += params["$filter"].length > 0 ? ` and (${filter})` : `${filter}`;
    }
    return this.get("/JobDivisionPayLocation", params);
  }
  
  dropPayLocation(selectProps: string, filter: string | null, active?:number): Promise<any> {
    let params: any = {
      $select: selectProps,
      $filter: filter,
    };
    return this.get("/PayLocation", params);
  }

  getListGPEmployee(selectProps: string, req: any | null): Promise<any> {
    try {
      let params: any = {
        $select: selectProps,
      };

      if(req){
        if(req.filter){
          params["$filter"] = req.filter;
        }

        if (req.orderBy) {
          params["$orderby"] = req.orderBy;
        }
        
        params["$count"] = req.count ? req.count : true;
        params["$top"] = req.pageSize ? req.pageSize : 100;
        params["$skip"] = req.pageSize * (req.currentPage ? req.currentPage - 1 : 0);
        
      } else {
        params["$count"] = true;
        params["$top"] = 1;
        params["$skip"] = 0;
      }
      const response = this.get("/GPEmployee", params);
      return response;

    } catch (error) {
      throw (error as AxiosError).response;
    }

  }
  
  getGroupMember(selectProps: string, expand: string | null): Promise<any> {
    try {
      let params: any = {
        $select: selectProps,
        $Count: true
      };
      if (expand) {
        params["$expand"] = expand;
      }
      return this.get("/approvalGroup", params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
  }
  
  sendWelcomeEmail(params: any) {
    try {
      if(params['Users'].length > 0){
        let arrPromise = [];
        for(let i = 0; i < params['Users'].length; i ++ ){
          const promise = this.sendWelcomeByEmail(params.Users[i].UserId);
          arrPromise.push(promise);
        }
        return Promise.all(arrPromise);
      }
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }
  sendWelcomeByEmail(id: any): Promise<any> {
    try {
      return this.get(`/Profile/SendWelComeEmail/${id}`);

    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  getAprrovalgroup(selectProps: string, expand: string | null): Promise<any> {
    try {
      let params: any = {
        $select: selectProps,
        $Count: true
      };
      if (expand) {
        params["$expand"] = expand;
      }
      params["$filter"] = 'Status eq 1';
      params["$Top"] = 100;
      params["$Skip"] = 0;
      return this.get("/approvalGroup", params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  selectUserLogs(req: any | null): Promise<any> {
    try {
      let params: any = {};

      if(req){
        if(req.filter){
          params["$filter"] = req.filter;
        }
        if(req.UserLogType !== null){
          params["UserLogType"] = req.UserLogType;
        }
        if(req.orderBy){
          params["$orderBy"] = req.orderBy;
        }
        params["$count"] = req.count ? req.count : true;
        params["$top"] = req.pageSize ? req.pageSize : 100;
        params["$skip"] = req.pageSize * (req.currentPage ? req.currentPage - 1 : 0);
        
      } else {
        params["$count"] = true;
        params["$top"] = 1;
        params["$skip"] = 0;
      }
      const response = this.get("/UserLog", params);
      return response;

    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }
}


