import { MutationTree } from "vuex";
import { ManualState, SET_PERCENT_UPLOAD} from "./types";

export const mutations: MutationTree<ManualState> = {
    SET_PERCENT_UPLOAD: (state: ManualState, percent: number) => {
        state.percent = percent;
    },
    SET_LOADING_MANUAL: (state: ManualState, status: boolean) => {
        state.loading = status
    },
  
};
