import { DashboardState } from './types'

export const state: DashboardState = {
  dropdownSite: [],
  action: undefined,
  timeSheets: [],
  countTimeSheetStatus: {
    total: 0,
    totalApproved: 0,
    totalCanceled: 0,
    totalDraft: 0,
    totalPendingApproved: 0,
    totalDeclined: 0
  },
  filterTimeSheet: {},
  countNotiUnRead: 0,
  loading: false,
  isEditAddTask: false,
  totalHour: 0,
  timeSheetsLine: []
}