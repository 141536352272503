import { RootState } from "store";
import { ActionTree } from "vuex";
import { UserService } from "../service";
import { UserState } from "./types";
const service = new UserService()

export const actions: ActionTree<UserState, RootState> = {
  
  async insert({ commit }, params: any, options?: any) {
    if(params){
      const data = await service.insert(params, options);
      return data;
    }
  },
  
  async update ({ commit }, params: any) {
    if(params){
      const data = await service.update(params);
      return data;
    }
  },

  async updateDetail ({ commit }, params: any) {
    if(params){
      const data = await service.updateDetail(params);
      return data;
    }
  },

  async updateStatus ({ commit }, params: any) {
    if(params){
      const newStatus = params.UserStatus;
      const data = await service.updateStatus(params, newStatus);
      return data;
    }
  },

  async select({ commit }, params: any) {
    if(params) {
      let filter = "";
      if(params.conditionSearch) {
        filter = params.conditionSearch.join(' and ');
      }
      const selectProps = ""; 
      params.filter = filter;
      const response = await service.select(selectProps, params);
      return response;
    }
    return [];
  },

  async selectUserLog({ commit }, params: any) {
    if(params) {
      let filter = "";
      if(params.conditionSearch) {
        filter = params.conditionSearch.join(' and ');
      }
      if(!params?.orderBy) {
        params.orderBy = 'UserLogDateTime desc'
      }
      params.filter = filter;
      const response = await service.selectUserLogs(params);
      commit("SET_USER_LOGS", response.value);
      commit("SET_USER_LOGS_COUNT", response["@odata.count"]);
    }
  },

  async selectByUserId({ commit }, params: any) {
    if(params) {
      let filter = "";
      if(params.Email) {
        filter = `tolower(Email) eq tolower('${params.Email}')`;
      }
      const selectProps = ""; 
      params.filter = filter;
      const response = await service.selectByUserId(selectProps, params);
      return response;
    }
    return [];
  },

  async selectUserById({ commit }, id: any) {
    if(id) {
      const response = await service.selectUserById(id);
      return response;
    }
    return [];
  },

  async isExistEmail({ commit }, email: any) {
    if(email) {
      const filter = `tolower(Email) eq tolower('${email}')`;
      const response = await service.isExistEmail(filter);
      if(response.value && response.value.length > 0){
        return true;
      }
      return false;
    }
    return false;
  },

  async appovalGroupList({ commit }, params: any) {
      let selectProps = "";
      let expand = "";
      const filter = "";
      const data = await service.appovalGroupList(selectProps, filter,  params.limit);
      return data.value;
  },
  
  async delete({ commit }, params: any) {
    if(params){
      const data = await service.deleteUsers(params);
      return data;
    }
  },

  async dropDownDivisionPayLocation({ commit }, params: any) {
    let filter = ``;
    const active = params?.active
    if (params.search) {
      filter = `contains(tolower(PayLocation),'${params.search.toLowerCase()}')`
    }
    const data = await service.dropDownDivisionPayLocation("JobDivisionPayLocationId, PayLocation, Division, Active", filter, active)
    return data.value
  },
  async dropPayLocation({ commit }, params: any) {
    let filter = ``;
    if (params.search) {
      filter = `contains(tolower(Name),'${params.search.toLowerCase()}')`
    }
    const data = await service.dropPayLocation("Name", filter)
    return data.value
  },
  
  async getListGPEmployee({ commit }, params: any) {
    if(params) {
      let filter = "";

      if(params.conditionSearch) {
        filter = params.conditionSearch.join(' and ');
      }
      const selectProps = ""; 
      params.filter = filter;
      const response = await service.getListGPEmployee(selectProps, params);
      return response;
    }
    return [];
  },

  async getGroupMember({ commit }, params: any) {
    const expand =
      "ApprovalGroupMembers($expand=Approver($select=FirstName, LastName, Email, AvatarUrl, UserType))";
    const selectProps = "ApprovalGroupId, Name, Status, CreatedOn, UpdatedOn, GPBatchPrefix, GPBatchNotificationTo, GPBatchNotificationCC";
    const data = await service.getGroupMember(
      selectProps,
      expand,
    );
    return data.value;
  },

  async sendWelcomeEmail ({ commit }, params: any) {
    if(params){
      const data = await service.sendWelcomeEmail(params);
      return data;
    }
  },
  
  async getAprrovalgroup ({ commit }, params: any) {
    
    const expand =
      "ApprovalGroupMembers($expand=Approver), Users($count=true)";

    const selectProps = "ApprovalGroupId, Name, Status, CreatedOn, UpdatedOn, GPBatchPrefix, GPBatchNotificationTo, GPBatchNotificationCC";

    const data = await service.getAprrovalgroup(
      selectProps,
      expand,
    );
    return data.value
  },


};
