import { UserState } from './types'

export const state: UserState = {
    userList: [],
    userCount: 1,
    userParam: null,
    defaultStartTime: null,
    loading: false,
    employeeList: [],
    loadingEmployee: false,
    employeeCount: 1,
    loadingSendMail: false,
    sendMailResult: 'INIT',
    userLogs: [],
    userLogsCount: 1,
    loadingUserLogs: false,
}