import { BrowserCacheLocation, Configuration, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import env from "./core/env";

// Config object to be passed to Msal on creation
const msalConfig: Configuration = {
  auth: {
    clientId:
      env('VITE_VUE_APP_MSAL_CLIENT_ID') ?? 'e739cade-5a83-454e-84a7-117e4de09894',
    authority:
      env('VITE_VUE_APP_MSAL_AUTHORITY') ??
      'https://etsidentitiesdev.b2clogin.com/etsidentitiesdev.onmicrosoft.com/B2C_1_SignIn',
    redirectUri:
      env('VITE_VUE_APP_MSAL_REDIRECT_URI') ?? 'https://103.153.73.223/callback',
    // postLogoutRedirectUri: env('VITE_VUE_APP_MSAL_REDIRECT_LOGOUT_URI') ?? '/login',
    knownAuthorities: ['etsidentitiesdev.b2clogin.com'],
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['openid profile'],
};


/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */

export const tokenRequest = {
  scopes: ['https://etsidentitiesdev.onmicrosoft.com/api/System.Admin', 
           'https://etsidentitiesdev.onmicrosoft.com/api/Timesheet.Submit',
           'https://etsidentitiesdev.onmicrosoft.com/api/Timesheet.Approve',
           'https://etsidentitiesdev.onmicrosoft.com/api/Timesheet.Review',
          'https://etsidentitiesdev.onmicrosoft.com/api/User.Manage',
           'https://etsidentitiesdev.onmicrosoft.com/api/System.Admin',
           'openid', 'profile', 'offline_access'],
  forceRefresh: true, // Set this to "true" to skip a cached token and go to the server to get a new token
};


// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};