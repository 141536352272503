<template>
  <el-dialog v-model="dialogVisible" :title="title" width="1000px" class="elAppproveGroup p-10"
    :before-close="handleClose" :show-close="true" :close-on-click-modal="false"
    top="4vh">
    <el-form ref="ruleFormRef" :model="group" :rules="rules" label-width="auto" size="default" status-icon
      require-asterisk-position="right" label-position="left" maxHeight="65vh">
      <el-row>
        <el-col :xs="{ span: 24 }" :md="{ span: 12 }" class="pr-10">
          <el-form-item class="label-bold-item align-middle mb-4 block" label="Approval Group Name" prop="Name" size="large" >
            <el-input v-model="group.Name" placeholder="Enter Approval Group Name" @blur="formValueChange"
              required />
          </el-form-item>

          <el-form-item v-if="group.ApprovalGroupId" class="label-bold-item align-middle block" label="Approval Group ID" prop="Id">
            <el-input :model-value="group.ApprovalGroupId ?? ''" disabled size="large" @blur="formValueChange" required class="mt-2"/>
          </el-form-item>
          
          <el-form-item v-if="!group.ApprovalGroupId" class="label-bold-item align-middle mb-6 block" label="GP Batch Prefix" prop="GPBatchPrefix" size="large" >
            <el-input v-model="group.GPBatchPrefix" placeholder="Enter GP Batch Prefix" @blur="formValueChange"
              required maxlength="6"/>
          </el-form-item>
        </el-col>
        
        <el-col :xs="{ span: 24 }" :md="{ span: 12 }" class="pl-10">
          <el-form-item v-if="group.ApprovalGroupId" class="label-bold-item align-middle mb-6 block" label="GP Batch Prefix" prop="GPBatchPrefix" size="large" >
            <el-input v-model="group.GPBatchPrefix" placeholder="Enter GP Batch Prefix" @blur="formValueChange"
              required maxlength="6"/>
          </el-form-item>
          
          <el-form-item v-if="group.ApprovalGroupId" :class="!emailErrorTo ? 'label-bold-item align-middle mb-4 block' : 'label-bold-item align-middle mb-0 block'" label="GP Batch Notification Recipients (To)" prop="GPBatchNotificationTo" size="large" >
            <el-input v-model="group.GPBatchNotificationTo" placeholder="Enter Email" @blur="EmailValidator(group.GPBatchNotificationTo ?? '', 'emailErrorTo')"
              required class="fixed-height-textarea" type="textarea" oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'/>
          </el-form-item>
          <el-form-item v-else :class="!emailErrorTo ? 'label-bold-item align-middle mb-4 block' : 'label-bold-item align-middle mb-0 block'" label="GP Batch Notification Recipients (To)" prop="GPBatchNotificationTo" size="large" >
            <el-input v-model="group.GPBatchNotificationTo" placeholder="Enter Email" @blur="EmailValidator(group.GPBatchNotificationTo ?? '', 'emailErrorTo')"
              required class="fixed-height-textarea" type="textarea" oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'/>
          </el-form-item>
          <div v-if="emailErrorTo" class="text-[#f56c6c] text-[12px]">{{ emailErrorTo }}</div>
          
          <el-form-item v-if="group.ApprovalGroupId" :class="!emailErrorCC ? 'label-bold-item align-middle mb-4 block' : 'label-bold-item align-middle mb-0 block'" label="GP Batch Notification Recipients (CC)" prop="GPBatchNotificationCC" size="large" >
            <el-input v-model="group.GPBatchNotificationCC" placeholder="Enter Email" @blur="EmailValidator(group.GPBatchNotificationCC ?? '', 'emailErrorCC')"
              required class="fixed-height-textarea" type="textarea" oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'/>
          </el-form-item>
          <el-form-item v-else :class="!emailErrorCC ? 'label-bold-item align-middle mb-4 block' : 'label-bold-item align-middle mb-0 block'" label="GP Batch Notification Recipients (CC)" prop="GPBatchNotificationCC" size="large" >
            <el-input v-model="group.GPBatchNotificationCC" placeholder="Enter Email" @blur="EmailValidator(group.GPBatchNotificationCC ?? '', 'emailErrorCC')"
              required class="fixed-height-textarea" type="textarea" oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'/>
          </el-form-item>
          <div v-if="emailErrorCC" class="text-[#f56c6c] text-[12px]">{{ emailErrorCC }}</div>
        </el-col>
      </el-row>

      <div v-if="!group.ApprovalGroupId" class="separated"></div>
    </el-form>
    <el-row class="approve-group min-w-[680px]">
      <el-col :span="10" class="min-w-[416px]">
        <div class="h-[52vh]">
          <label class="font-semibold text-xl">Available Approvers</label>
          <el-input v-model="searchUser" class="mt-[12px] h-[40px]" placeholder="Search Name or Email"
            clearable></el-input>
          <div class="mt-[8px]">
            <el-scrollbar height="40vh">
              <div class="align-middle flex py-[10px] pl-[8px] mb-[8px] cursor-pointer" v-for="item in listUser"
                :key="item.UserId" @click="handleSelectAvailableUser(item.UserId ?? '')"
                :class="listSelectedUserToAddGroup.includes(item.UserId ?? '') ? 'select-user' : ''">
                <div class="text-center min-w-[32px] mr-[8px]">
                  <img class="inline-block h- w-8 rounded-full border-avatar"
                    :src="!item.AvatarUrl ? avatarDefault : `${imageEndpoint}/${item.AvatarUrl}?t=${Date.now()}`"
                    alt="avatar" />
                </div>
                <div class="col-span-3 pr-[8px]">
                  <div class="flex flex-col">
                    <div class="text-sm font-semibold text-name">{{ item.FullName }}</div>
                    <div class="text-xs font-medium text-email">{{ item.Email }}</div>
                  </div>
                </div>
              </div>
            </el-scrollbar>

          </div>
        </div>
      </el-col>
      <el-col :span="4" class="max-w-[86px]">
        <div class="flex flex-col items-center justify-center h-full">
          <el-button class="arrow-button border-none p-[0px] h-[40px] w-[40px] ml-0 p-[8px]"
            @click="removeUserApproveGroup()"
            :class="listSelectedUserToRemoveGroup.length ? 'arrow-button-selected' : ''">
            <icon-svg width="16" height="16" name="arrow-left-bold" :iconColor="iconColorRemove" />
          </el-button>
          <el-button class="arrow-button border-none p-[0px] h-[40px] w-[40px] ml-0 mt-2 p-[8px]"
            @click="addUserToApproveGroup()" :class="listSelectedUserToAddGroup.length ? 'arrow-button-selected' : ''">
            <icon-svg width="16" height="16" name="arrow-right-bold" :iconColor="iconColor" />
          </el-button>
        </div>
      </el-col>
      <el-col :span="10" class="min-w-[416px]">
        <div class="h-[52vh]">
          <label class="font-semibold text-xl">Selected Approvers</label>
          <el-input v-model="searchSeletedUser" class="mt-[12px] h-[40px]" placeholder="Search Name or Email"
            clearable></el-input>
          <div class="mt-[8px]">
            <el-scrollbar height="40vh">
              <div class="align-middle flex py-[10px] pl-[8px] mb-[8px] cursor-pointer"
                v-for="item in selectGroupAppliedSearch" :key="item.UserId"
                @click="handleSelectUserApproveGroup(item.UserId ?? '')"
                :class="listSelectedUserToRemoveGroup.includes(item.UserId ?? '') ? 'select-user' : 'selected-user'">
                <div class="text-center min-w-[32px] mr-[8px]">
                  <img class="inline-block h- w-8 rounded-full border-avatar"
                    :src="!item.AvatarUrl ? avatarDefault : `${imageEndpoint}/${item.AvatarUrl}?t=${Date.now()}`" />
                </div>
                <div class="col-span-3 pr-[8px]">
                  <div class="flex flex-col">
                    <div class="text-sm font-semibold text-name">{{ item.FullName }}</div>
                    <div class="text-xs font-medium text-email">{{ item.Email }}</div>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </el-col>
    </el-row>
    <template #footer>
      <div class="flex justify-end ... p-[0px] py-[16px]">
        <el-button @click="handleClose()" class="btn-cancel">Cancel</el-button>
        <el-button v-if="emailErrorTo.length == 0 && emailErrorCC.length == 0" type="primary" class="btn-confirm ml-[20px]" @click="insert(ruleFormRef)"
          :disabled="!group.ApprovalGroupId ? !(selectGroupModel.length > 0) : false">
          <span>{{ titleBtn }}</span>
        </el-button>
        <el-button v-else type="primary" class="btn-confirm ml-[20px]" disabled>
          <span>{{ titleBtn }}</span>
        </el-button>
      </div>
    </template>
  </el-dialog>
  <PopupConfirm :type="'Closing'" :message="'Your data won’t be saved.\nAre you sure?'" :lblBtnSubmit="'Yes'"
    :iconPopup="'canceling-warning'" :iconBtnName="'check-mark-circle'" @submit="handleCloseConfirm"
    ref="popupConfirmClose">
  </PopupConfirm>
</template>
<script lang="ts">
import { computed, defineComponent, reactive, ref, watch, onMounted, inject } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import TimePicker from "./TimePicker.vue";
import { useStore } from "vuex";
import { MESSAGE_CODES } from "core/constants";
import _ from 'lodash';
import IconSvg from '@/layouts/IconBase.vue'
import { imageEndpoint } from "config/enviroments";
import avatarDefault from '@/assets/avatar.png';
import PopupConfirm from "@/components/PopupConfirm.vue";

export default defineComponent({
  name: "UserForm",
  props: {},
  components: {
    TimePicker,
    IconSvg,
    PopupConfirm
  },
  setup(props) {
    const store = useStore();
    const isOpenPopupGroupForm = computed(
      () => store.state.isOpenPopupGroupForm
    );
    const editApproveGroup = computed(
      () => store.state.editApproveGroup
    );
    const filterSelect = computed(
      () => store.state.filterSelect
    );

    const ruleFormRef = ref<FormInstance>();
    const dialogVisible = ref<boolean>(false);

    const loading = ref(false);
    const isEnableBtnConfirm = ref<boolean>(true);

    const notify = inject('notify', (type: string, message: string) => ({}));

    const title = ref<string>('Create Approval Group');

    const titleBtn = ref<string>('Create Group');
    const searchUser = ref<string>('');
    const searchSeletedUser = ref<string>('');
    const listSelectedUserToAddGroup = ref<string[]>([])
    const listSelectedUserToRemoveGroup = ref<string[]>([])
    const popupConfirmClose = ref<InstanceType<typeof PopupConfirm>>();

    const selectGroupModel = ref<UserModelSelect[]>([]);
    // const selectGroupModelTags = ref<UserModelSelect[]>([]);

    const emailErrorTo = ref<string>("")
    const emailErrorCC = ref<string>("")

    const group = ref<GroupModel>({
      Name: "",
      ApprovalGroupMembers: [],
      GPBatchPrefix: "",
      GPBatchNotificationTo: "",
      GPBatchNotificationCC: ""
    });

    const listUser = ref<UserModel[]>([]);
    const defaultListUser = ref<UserModel[]>([]);

    const EmailValidator = (value: string, type: string) => {
      const emails: any = value.split(";").map((email: any) => email.trim());
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (emails.length > 0) {
        for (const email of emails) {
          if (!emailRegex.test(email)) {
            if (type == "emailErrorTo") {
              emailErrorTo.value = "Contains one or more email addresses, separated by semicolons";
            } else {
              if (group?.value?.GPBatchNotificationCC) {
                if (group?.value?.GPBatchNotificationCC.length >= 1) {
                  emailErrorCC.value = "Contains one or more email addresses, separated by semicolons";
                } 
              } else {
                emailErrorCC.value = "";
              }
            }
            return;
          }
        }
      }
      if (type == "emailErrorTo") {
        emailErrorTo.value = "";
      } else {
        emailErrorCC.value = "";
      }
    }

    const CheckEmailTo = (rule: any, value: any) => {
      if (!value) {
        emailErrorTo.value = "Please enter GP Batch Notification Recipients To"
        return
      }
    }
    
    // const CheckEmailCC = (rule: any, value: any) => {
    //   if (!value) {
    //     emailErrorCC.value = "Please enter GP Batch Notification Recipients CC"
    //     return
    //   }
    // }

    const rules = reactive<FormRules>({
      Name: [
        {
          required: true,
          message: "Please enter Group Name",
          trigger: "blur",
        },
      ],
      Approvers: [
        {
          required: true,
          message: "Please enter Approver",
          trigger: "blur",
        },
      ],
      GPBatchPrefix: [
        {
          required: true,
          message: "Please enter GP Batch Prefix",
          trigger: ["blur", "change"],
        },
      ],
      GPBatchNotificationTo: [
        {
          required: true,
          trigger: ["blur", "change"],
          validator: CheckEmailTo
        },
      ],
      GPBatchNotificationCC: [
        {
          required: false,
          trigger: ["blur", "change"],
          // validator: CheckEmailCC
        },
      ],
    });
    const handleClose = () => {
      if (isTouched.value) {
        popupConfirmClose.value.showDialog = true;
      } else {
        handleCloseConfirm();
      }
      emailErrorTo.value = ""
      emailErrorCC.value = ""
    };

    const handleCloseConfirm = () => {
      ruleFormRef.value?.resetFields();
      popupConfirmClose.value.showDialog = false;
      clearNewPopup();
      store.commit('SET_EDIT_GROUP_FORM', null)
      store.commit("SET_OPEN_POPUP_GROUP_FORM", false);
    }

    const handleCloseTag = (user: any) => {
      const index = selectGroupModel.value!.indexOf(user);
      if (index > -1) {
        selectGroupModel.value!.splice(index, 1);
      }
    }
    const clearNewPopup = () => {
      selectGroupModel.value = [];
      group.value = {
        Name: "",
        ApprovalGroupMembers: [],
        GPBatchPrefix: "",
        GPBatchNotificationTo: "",
        GPBatchNotificationCC: "",
      };
      listSelectedUserToAddGroup.value = [];
      listSelectedUserToRemoveGroup.value = [];
      searchUser.value = '';
      searchSeletedUser.value = '';
      listUser.value = defaultListUser.value
    }

    watch(isOpenPopupGroupForm, () => {
      dialogVisible.value = isOpenPopupGroupForm.value;
    });

    watch(editApproveGroup, () => {
      if (editApproveGroup.value) {
        title.value = 'Edit Approval Group';
        titleBtn.value = 'Save Changes';
        selectGroupModel.value = [];
        listUser.value = [];
        group.value = Object.assign({}, editApproveGroup.value);
        emailErrorTo.value = ""
        emailErrorCC.value = ""
        if (group.value) {
          const userIds: any = []
          for (let i = 0; i < group.value.ApprovalGroupMembers?.length; i++) {
            let editItem = group.value.ApprovalGroupMembers[i];
            editItem.FullName = `${editItem.Approver?.FirstName} ${editItem.Approver?.LastName ? editItem.Approver.LastName : ""}`;
            selectGroupModel.value.push(
              {
                UserId: editItem.ApproverId,
                FullName: editItem.FullName,
                Email: editItem.Approver?.Email,
                AvatarUrl: editItem.Approver?.AvatarUrl,
                UserStatus: editItem?.Approver?.UserStatus
              }
            );
            userIds.push(editItem.ApproverId);
          }
          store.dispatch("approvalGroup/userList", { query: null, userIds }).then((data) => {
            listUser.value = data;
            loading.value = false;
          });
        }

      } else {
        title.value = 'Create Approval Group';
        titleBtn.value = 'Create Group';
        clearNewPopup();
        store.dispatch("approvalGroup/userList", { query: null }).then((data) => {
          listUser.value = data;
          loading.value = false;
          defaultListUser.value = data
        });
      }
    });

    watch(searchUser, () => {
      const userIds: any = selectGroupModel.value.map((u) => u.UserId);
      store.dispatch("approvalGroup/userList", { query: searchUser.value, userIds }).then((data) => {
        listUser.value = data;
        loading.value = false;
      });
    })

    const userListRemote = async (query: any, limit?: number) => {
      if (query) {
        loading.value = true
        let params = {
          query: query,
          limit: 100,
        };
        store.dispatch("approvalGroup/userList", params).then((data) => {
          listUser.value = data;
          loading.value = false;
        });

      } else {
        listUser.value = []
      }
    };


    const formValueChange = async () => {
      if (listUser.value.length > 0 && group.value.Name) {
        isEnableBtnConfirm.value = true;
      } else {
        isEnableBtnConfirm.value = false;
      }

    }

    const refeshMainPage = () => {
      store.commit('SET_LOADING', true);
      store.dispatch("approvalGroup/select", filterSelect.value).then((data) => {
        // lsGroups.value = data;
        store.commit('approvalGroup/SET_LIST_APPROVAL', data);
        store.commit('SET_LOADING', false);
      });
    };

    const insert = async (formEl: FormInstance | undefined) => {
      try {
        let lsApprover = Array<UserModelSelect>();
        const filterSelectGroupModel = selectGroupModel.value?.filter((user: any) => user.UserStatus === 1)

        for (let i = 0; i < filterSelectGroupModel.length; i++) {
          lsApprover.push({
            ApproverId: filterSelectGroupModel[i].UserId,
          })
        }
        group.value.ApprovalGroupMembers = [...lsApprover];
        const paramBody = Object.assign({}, group.value);
        let validForm = true;
        isEnableBtnConfirm.value = true;
        if (!paramBody.GPBatchNotificationTo) {
          emailErrorTo.value = "Please enter GP Batch Notification Recipients To"
        }
        
        if (!paramBody.Name && !paramBody.GPBatchPrefix) {
          if (!formEl) return
          await formEl.validate((valid, fields) => {
            validForm = valid;
          })
        }
        //process data
        if (validForm && paramBody && paramBody.Name && paramBody.GPBatchPrefix && paramBody.GPBatchNotificationTo) {
          isEnableBtnConfirm.value = false;
          if (!editApproveGroup.value) {
            store.commit('SET_LOADING', true);
            store.dispatch("approvalGroup/insert", paramBody,).then((response) => {
              store.commit('SET_LOADING', false);
              store.commit("SET_OPEN_POPUP_GROUP_FORM", false);
              notify('success', MESSAGE_CODES.MSG_SAVE_SUCCESS);
              isEnableBtnConfirm.value = true;
              clearNewPopup();
              refeshMainPage();
            });

          } else {
            const _paramBody: any = { ...paramBody }
            delete _paramBody['Users@odata.nextLink']
            store.commit('SET_LOADING', true);
            store.dispatch("approvalGroup/update", _paramBody,).then((response) => {
              store.commit('SET_LOADING', false);
              store.commit("SET_OPEN_POPUP_GROUP_FORM", false);
              store.commit("SET_AFTER_EDIT_APPROVE_GROUP", true);
              notify('success', MESSAGE_CODES.MSG_SAVE_SUCCESS);
              isEnableBtnConfirm.value = true;
              clearNewPopup();
              refeshMainPage();
            }).catch((error) => {
              store.commit('SET_LOADING', false);
              if (error?.data?.value) {
                notify('error', error?.data?.value);
              }
            });
          }
        }

        store.commit('SET_LOADING', false);

      } catch (error) {
        isEnableBtnConfirm.value = true;
        store.commit('SET_LOADING', false);

      }
    }

    const approverGenvalue = (item: any) => {
      let val = "";
      if (item) {
        val = `${item.FullName}`;
      }
      return val;
    }

    const getApproval = (userId: any) => {
      const item = listUser.value.filter((ob) => ob.UserId == userId);
      if (item && item.length > 0)
        return item[0];
      return null;
    }
    const handleSelectAvailableUser = (userId: string) => {
      if (listSelectedUserToAddGroup.value.includes(userId)) {
        listSelectedUserToAddGroup.value = _.remove(listSelectedUserToAddGroup.value, function (id) {
          return id != userId;
        });
      } else {
        listSelectedUserToAddGroup.value.push(userId);
      }
    }

    const handleSelectUserApproveGroup = (userId: string) => {
      if (listSelectedUserToRemoveGroup.value.includes(userId)) {
        listSelectedUserToRemoveGroup.value = _.remove(listSelectedUserToRemoveGroup.value, function (id) {
          return id != userId;
        });
      } else {
        listSelectedUserToRemoveGroup.value.push(userId);
      }
    }

    const addUserToApproveGroup = () => {
      if (listSelectedUserToAddGroup.value.length == 0) {
        return;
      }
      listSelectedUserToAddGroup.value.forEach((userId: string) => {
        const user = listUser.value.find(i => i.UserId == userId);
        if (!selectGroupModel.value.find(x => x.UserId == userId)) {
          selectGroupModel.value.push({
            UserId: user?.UserId,
            FirstName: user?.FirstName,
            LastName: user?.LastName,
            FullName: user?.FullName,
            Email: user?.Email,
            AvatarUrl: user?.AvatarUrl,
            UserStatus: user?.UserStatus
          })
        }

      })
      const userIds: any = selectGroupModel.value.map((u) => u.UserId);
      listSelectedUserToAddGroup.value = [];
      store.dispatch("approvalGroup/userList", { query: searchUser.value, userIds: userIds }).then((data) => {
        listUser.value = data;
        loading.value = false;
      });
    }

    const removeUserApproveGroup = () => {
      if (listSelectedUserToRemoveGroup.value.length == 0) {
        return;
      }
      selectGroupModel.value = _.filter(selectGroupModel.value, function (u) {
        return !listSelectedUserToRemoveGroup.value.includes(u.UserId!)
      });
      const userIds = selectGroupModel.value.map((u) => u.UserId)
      listSelectedUserToRemoveGroup.value = [];
      store.dispatch("approvalGroup/userList", { query: searchUser.value, userIds: userIds }).then((data) => {
        listUser.value = data;
        loading.value = false;
      });
    }

    const selectGroupAppliedSearch = computed(() => {
      const filterSelectGroupModel = selectGroupModel.value?.filter((user: any) => user.UserStatus === 1)
      if (searchSeletedUser.value) {
        const keyword = searchSeletedUser.value.toLowerCase()
        const value = filterSelectGroupModel.filter((user) => {
          return user.FullName?.toLowerCase()?.includes(keyword) || user.Email?.toLowerCase()?.includes(keyword)
        })
        return value
      }
      return filterSelectGroupModel
    })

    const isTouched = computed(() => {
      if (editApproveGroup.value) {
        const isNameChanged = editApproveGroup.value.Name != group.value.Name
        const isGPBatchPrefixChanged = editApproveGroup.value.GPBatchPrefix != group.value.GPBatchPrefix
        const isGPBatchNotificationToChanged = editApproveGroup.value.GPBatchNotificationTo != group.value.GPBatchNotificationTo
        const isGPBatchNotificationCCChanged = editApproveGroup.value.GPBatchNotificationCC != group.value.GPBatchNotificationCC
        const isMemberChanged = selectGroupModel.value.length != editApproveGroup.value.ApprovalGroupMembers?.length
          && !!selectGroupModel.value.find((member) => !editApproveGroup.value.ApprovalGroupMembers.includes(member.UserId))

        return isNameChanged || isMemberChanged || isGPBatchPrefixChanged || isGPBatchNotificationToChanged || isGPBatchNotificationCCChanged
      } else {
        return group.value.Name || selectGroupModel.value.length || group.value.GPBatchPrefix || group.value.GPBatchNotificationTo || group.value.GPBatchNotificationCC
      }
    })

    const iconColor = computed(() =>
      (!store.state.currentTheme || store.state.currentTheme === 'dark') ||
        (listSelectedUserToAddGroup.value.length) ? "#FFFFFF" : "#262A34"
    );
    const iconColorRemove = computed(() =>
      (!store.state.currentTheme || store.state.currentTheme === 'dark') ||
        (listSelectedUserToRemoveGroup.value.length) ? "#FFFFFF" : "#262A34"
    );

    onMounted(() => {
      // userListRemote("");
    });

    return {
      editApproveGroup,
      ruleFormRef,
      rules,
      group,
      dialogVisible,
      title,
      loading,
      selectGroupModel,
      titleBtn,
      handleClose,
      handleCloseConfirm,
      listUser,
      isEnableBtnConfirm,
      handleCloseTag,
      userListRemote,
      approverGenvalue,
      insert,
      getApproval,
      refeshMainPage,
      formValueChange,
      searchUser,
      searchSeletedUser,
      handleSelectAvailableUser,
      listSelectedUserToAddGroup,
      listSelectedUserToRemoveGroup,
      handleSelectUserApproveGroup,
      addUserToApproveGroup,
      removeUserApproveGroup,
      iconColor,
      iconColorRemove,
      imageEndpoint,
      avatarDefault,
      selectGroupAppliedSearch,
      popupConfirmClose,
      emailErrorTo,
      emailErrorCC,
      EmailValidator
    };
  },
});

</script>
<style lang="scss">
.separated {
  border-bottom: 1px dashed #e9e8e8;
}

.dark {
  .select-custom .el-select__tags .el-select__input {
    background: none;
  }

  .separated {
    border-bottom: 1px dashed #3A4056;
    padding-top: 8px;
  }

  .elAppproveGroup {
    .el-scrollbar {
      border: 1px solid #3a4156;
    }
  }
  .fixed-height-textarea {
    .el-textarea__inner {
      resize: none;
      height: 38px;
      max-height: 5em !important;
      color: #fff;
      line-height: 1.7;
    }
    .el-textarea__inner:focus {
      font-weight: 600;
    }
    .el-textarea__inner::placeholder {
      line-height: 1.8;
    }
  }
  .label-bold-item .el-form-item__label-wrap .el-form-item__label {
    font-weight: 600;
  }
}

.select-custom {
  .el-select__tags .el-select__input:focus {
    border: 0 none;
    box-shadow: none;
  }
}

.select-custom-popper {
  .el-select-dropdown__item {
    height: 100%;
  }
}

.not-found img {
  height: 28px !important;
  width: 28px !important;
}

.el-select__input {
  background-color: transparent;
  border-color: transparent;
}

.elAppproveGroup {
  .el-dialog__body {
    margin-bottom: 24px;
  }

  .el-dialog__header {
    margin-bottom: 24px !important;
  }

  .el-dialog__footer {
    padding: 0px;
  }

  .el-form-item--default {
    margin-bottom: 24px;

    .el-form-item__label {
      margin-right: 24px !important;
    }
  }

  .el-form-item.el-form-item--default.asterisk-right.el-form-item--feedback.align-middle {
    margin-bottom: 16px;
  }

  .el-form.el-form--default.el-form--label-left {
    margin-bottom: 22px;
  }

  .el-scrollbar {
    padding: 8px;
    border: 1px solid #e9e8e8;
    border-radius: 6px;
  }
}
.fixed-height-textarea {
  .el-textarea__inner {
    resize: none;
    height: 38px;
    max-height: 5em !important;
    color: #231F20;
    line-height: 1.7;
  }
  .el-textarea__inner:focus {
    font-weight: 600;
  }
  .el-textarea__inner::placeholder {
    line-height: 1.8;
  }
}
.label-bold-item .el-form-item__label-wrap .el-form-item__label {
  font-weight: 600;
}
</style>