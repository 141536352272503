<template>
  <div>
    <StartTimeSheet
      :disable="!allowUpdate"
      :hours="taskInfo.startTime.hours"
      :minutes="taskInfo.startTime.mins"
      :taskId="taskId"
      :errorMessage="errors?.overlap?.id === index ? errors?.overlap?.message : null"
      @submit="handleInputTimeSheet"
      ref="refTimeSheet"
      :totalHour="totalHour"
    />
  </div>
  <el-form
    ref="refForm"
    :validate-on-rule-change="false"
    :model="taskInfo"
    :rules="rules"
    label-width="132px"
    size="default"
    status-icon
    require-asterisk-position="right"
    label-position="left"
  >
    <el-form-item label="Site" prop="site">
      <el-select
        v-model="taskInfo.site"
        :placeholder="isEdit ? ' ' : 'Select Site'"
        class="w-[400px]"
        size="large"
        filterable
        popper-class="select-custom-popper w-[390px]"
        remote
        clearable
        :remote-show-suffix="true"
        :remote-method="searchDropDownSite"
        :loading="loadingDropDownSite"
        @change="selectSite"
        :disabled="!allowUpdate"
        @visible-change="handleLoadSite"
        @blur="isLoadedSite = false"
      >
        <el-option
          v-for="item in dropdownSite"
          :key="`${item.Location}-${item.SiteId}`"
          :label="item.Location"
          :value="`${item.Location}-${item.SiteId}`"
        >
          <span style="float: left">{{ item.Location }}</span>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Fleet" prop="fleet">
      <el-select
        v-model="taskInfo.fleet"
        :placeholder="isEdit ? ' ' : 'Select Fleet'"
        class="w-[400px]"
        size="large"
        filterable
        clearable
        popper-class="select-custom-popper w-[390px]"
        @change="selectFleet"
        :filter-method="searchDropDownFleet"
        :disabled="!allowUpdate"
        @clear="clearField('fleet')"
        @visible-change="handleLoadFleet"
        @blur="isLoadedFleet = false"
      >
        <el-option
          v-for="item in dropdownFleet"
          :key="item.EquipmentId"
          :label="item.Description ? item.Name + ' - ' + item.Description : item.Name"
          :value="item.EquipmentId ? item.EquipmentId : 0"
        >
          <span style="float: left">{{ item.Name }}</span>
          <br />
          <span style="font-size: 13px">
            {{ item.Description }}
          </span>        
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Work Order" prop="workOrder">
      <el-select
        v-model="taskInfo.workOrder"
        :placeholder="isEdit ? ' ' : 'Select Work Order'"
        class="w-[400px]"
        size="large"
        filterable
        clearable
        :filter-method="handleFilterWorkOrder"
        :disabled="!allowUpdate"
        :loading="loadingDropDownWorkOrder"
        popper-class="select-custom-popper w-[390px]"
        @change="selectWorkOrder"
        @clear="clearField('workOrder')"
        @visible-change="handleLoadWorkOrder"
        @blur="isLoadedWorkOrder = false"
        >
        <el-option
          v-for="item in dropdownWorkOrder"
          :key="item.WorkOrderId"
          :label="item.Name"
          :value="item.WorkOrderId ? item.WorkOrderId : 0"
        >
          <span style="float: left">{{ item.Name }}</span>
          <br />
          <span style="font-size: 13px">
            {{ item.Description }}
          </span>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item class="work-order-description" label="Work Order Description" prop="workOrderDescription">
      <el-input
        v-model="taskInfo.workOrderDescription"
        :placeholder="isEdit ? ' ' : 'Work Order Description'"
        size="large"
        class="w-[400px]"
        disabled
      ></el-input>
    </el-form-item>
    <el-form-item label="Task" prop="workOrderTaskId">
      <el-select
        v-model="taskInfo.workOrderTaskId"
        :placeholder="isEdit ? ' ' : 'Select Task'"
        class="w-[400px]"
        size="large"
        filterable
        clearable
        :filter-method="handleFilterWorkOrderTask"
        popper-class="select-custom-popper w-[390px]"
        :disabled="!allowUpdate"
        :loading="loadingDropDownWorkOrderTask"
        @change="selectWorkOrderTask"
        @clear="clearField('workOrderTask')"
        @visible-change="handleLoadWorkOrderTask"
        @blur="isLoadedWorkOrderTask = false"
      >
        <el-option
          v-for="item in dropdownWorkOrderTask"
          :key="item.WorkOrderTaskId"
          :label="item.Description ? item.Name + ' - ' + item.Description : item.Name"
          :value="item.WorkOrderTaskId ? item.WorkOrderTaskId : 0"
        >
          <span style="float: left">{{ item.Name }}</span>
          <br />
          <span style="font-size: 13px">
            {{ item.Description }}
          </span>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Job Description" prop="jobDescription">
      <el-input
        v-model="taskInfo.jobDescription"
        :rows="2"
        type="textarea"
        class="w-[400px]"
        placeholder="Enter Job Description"
        size="large"
        :disabled="!allowUpdate"
        maxlength="1000"
      />
    </el-form-item>
    <div v-if="allowInputFiledService">
      <el-form-item label="Field Service" prop="additionInfo">
        <div class="flex justify-end w-[400px]">
          <el-switch
            v-model="taskInfo.additionInfo"
            size="large"
            :disabled="timeSheetStatus != 0 || allowUpdate == false"
          ></el-switch>
        </div>
      </el-form-item>
      <el-form-item
        label="Client Name"
        prop="clientName"
        v-if="taskInfo.additionInfo === true"
      >
        <el-input
          v-model="taskInfo.clientName"
          size="large"
          placeholder="Enter Client Name"
          class="w-[400px]"
          :disabled="timeSheetStatus != 0 || allowUpdate == false"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="Location"
        prop="location"
        v-if="taskInfo.additionInfo === true"
      >
        <el-input
          v-model="taskInfo.location"
          size="large"
          class="w-[400px]"
          placeholder="Enter Location"
          :disabled="timeSheetStatus != 0 || allowUpdate == false"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="Manufacturer"
        prop="manufacturer"
        v-if="taskInfo.additionInfo === true"
      >
        <el-input
          v-model="taskInfo.manufacturer"
          size="large"
          class="w-[400px]"
          placeholder="Enter Manufacturer"
          :disabled="timeSheetStatus != 0 || allowUpdate == false"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="Model"
        prop="model"
        v-if="taskInfo.additionInfo === true"
      >
        <el-input
          v-model="taskInfo.model"
          size="large"
          class="w-[400px]"
          placeholder="Enter Model"
          :disabled="timeSheetStatus != 0 || allowUpdate == false"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="Veh. Reg. No"
        prop="vehRegNo"
        v-if="taskInfo.additionInfo === true"
      >
        <el-input
          v-model="taskInfo.vehRegNo"
          size="large"
          class="w-[400px]"
          placeholder="Enter Veh. Reg. No"
          :disabled="timeSheetStatus != 0 || allowUpdate == false"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="Serial No"
        prop="serialNo"
        v-if="taskInfo.additionInfo === true"
      >
        <el-input
          v-model="taskInfo.serialNo"
          size="large"
          class="w-[400px]"
          placeholder="Enter Serial No"
          :disabled="timeSheetStatus != 0 || allowUpdate == false"
        ></el-input>
      </el-form-item>
      <div></div>
      <el-form-item
        label="SMU Hours"
        prop="SMUHour"
        v-if="taskInfo.additionInfo === true"
      >
        <img
          src="../assets/icon_clock.svg"
          height="16"
          width="16"
          class="el-icon-clock"
        />
        <el-input-number
          v-model="taskInfo.SMUHour"
          :controls="false"
          size="large"
          class="w-[150px]"
          :disabled="timeSheetStatus != 0 || allowUpdate == false"
        />
      </el-form-item>
      <el-form-item
        label="Travel KMs"
        prop="travelKMS"
        v-if="taskInfo.additionInfo === true"
      >
        <img
          src="../assets/icon_clock.svg"
          height="16"
          width="16"
          class="el-icon-clock"
        />
        <el-input-number
          v-model="taskInfo.travelKMS"
          :controls="false"
          size="large"
          class="w-[150px]"
          :disabled="timeSheetStatus != 0 || allowUpdate == false"
        />
      </el-form-item>
      <el-form-item
        label="Field Service Notes"
        prop="fieldServiceNote"
        v-if="taskInfo.additionInfo === true"
      >
        <el-input
          v-model="taskInfo.fieldServiceNote"
          :rows="3"
          type="textarea"
          placeholder="Enter Notes"
          size="large"
          class="w-[400px]"
          :disabled="timeSheetStatus != 0 || allowUpdate == false"
        />
      </el-form-item>
      <el-form-item
        label="Customer Email"
        prop="customerEmail"
        v-if="taskInfo.additionInfo === true"
      >
        <el-input
          v-model="taskInfo.customerEmail"
          size="large"
          class="w-[400px]"
          placeholder="Enter Customer Email"
          :disabled="timeSheetStatus != 0 || allowUpdate == false"
        ></el-input>
      </el-form-item>
    <el-form-item
      label="Customer Acceptance Name"
      prop="customerAcceptanceName"
      v-if="taskInfo.additionInfo === true"
      class="customer-acceptance-name"
    >
      <el-input
        v-model="taskInfo.customerAcceptanceName"
        size="large"
        placeholder="Enter Customer Acceptance Name"
        :disabled="timeSheetStatus != 0 || allowUpdate == false"
      ></el-input>
    </el-form-item>
      <el-form-item
        label="Customer Acceptance"
        prop="isCustomerAcceptance"
        v-if="taskInfo.additionInfo === true"
      >
        <Vue3Signature
          ref="signatureCustomer"
          :sigOption="sigOption"
          :h="'200px'"
          :w="'400px'"
          class="sign-pad"
          :disabled="timeSheetStatus != 0 || allowUpdate == false"
          @mousedown="(e: any) => handleSignatureClick(e, 'customer')"
        ></Vue3Signature>
        <div
          class="w-full flex justify-end mt-[8px]"
          v-if="timeSheetStatus == 0 && allowUpdate"
        >
          <el-button
            class="btn-cancel"
            @click="() => handleClearSignature('customer')"
            >Clear</el-button
          >
        </div>
      </el-form-item>
      <el-form-item
        label="Mechanical Acceptance"
        prop="isMechanicalAcceptance"
        v-if="taskInfo.additionInfo === true"
      >
        <Vue3Signature
          ref="signatureMechanical"
          :sigOption="sigOption"
          :h="'200px'"
          :w="'400px'"
          class="sign-pad"
          :disabled="timeSheetStatus != 0 || allowUpdate == false"
          @mousedown="(e: any) => handleSignatureClick(e, 'mechanical')"
        ></Vue3Signature>
        <div
          class="w-full flex justify-end mt-[8px]"
          v-if="timeSheetStatus == 0 && allowUpdate"
        >
          <el-button
            class="btn-cancel"
            @click="() => handleClearSignature('mechanical')"
            >Clear</el-button
          >
        </div>
      </el-form-item>
    </div>
  </el-form>
</template>
<script lang="ts">
import _ from 'lodash';
import {computed, defineComponent, onBeforeMount, onMounted, ref, watch,} from "vue";
import type {FormInstance} from "element-plus";
import {useStore} from "vuex";
import {Actions} from "modules/dashboard/store/types";
import {TASK_TYPES} from "core/constants";
import { imageEndpoint } from '@/config/enviroments';
import StartTimeSheet from "./StartTimeSheet.vue";

export default defineComponent({
  name: "RentalTask",
  props: {
    data: {
      index: Number,
      type: Object,
      taskId: String,
      workOrderTaskId: Number,
      workOrderId: Number,
      fleetId: Number,
      siteId: Number,
      additionInfo: Object,
      jobDescription: String,
      hours: Number,
      mins: Number,
      timeSheetStatus: Number,
    },
    errors: Object,
    isEdit: Boolean,
    allowUpdate: Boolean,
    taskTypeDisplay: Object,
    totalHour: Number,
  },
  components: {
    StartTimeSheet,
  },
  setup(props, ctx) {
    const refForm = ref<FormInstance>();
    const errors = ref<any>(props.errors);
    const isEdit = ref<any>(props.isEdit);
    const taskId = ref<string>(props.data?.taskId ?? "");
    const WorkOrderQueryBySite = ref<any>();
    const WorkOrderTaskQueryBySite = ref<any>();
    const WorkOrderTaskQueryByFleet = ref<any>();
    const DEFAULT_TASK_INFO: any = {
      site: undefined,
      fleet: undefined,
      workOrder: undefined,
      workOrderDescription: "",
      task: undefined,
      jobDescription: "",
      additionInfo: false,
      clientName: "",
      location: "",
      manufacturer: "",
      model: "",
      vehRegNo: "",
      serialNo: "",
      SMUHour: null,
      travelKMS: null,
      customerEmail: "",
      fieldServiceNote: "",
      customerAcceptance: "",
      mechanicalAcceptance: "",
      isCustomerAcceptance: "",
      isMechanicalAcceptance: "",
      workOrderTaskId: undefined,
      startTime: {
        hours: props.data?.hours ?? 0,
        mins: props.data?.mins ?? 0,
      },
      TimesheetLineMinutes: 0,
      customerAcceptanceName: "",
      equipmentId:"",
    }
    const defaultValueStr = JSON.stringify(DEFAULT_TASK_INFO)
    const taskInfo = ref<RentalTask>({ ...DEFAULT_TASK_INFO });
    const rules: any = computed(() =>({
      site: [
        {
          required: true,
          message: "Please select Site",
          trigger: isEdit.value ? ["blur"] : ["blur", "change"],
        },
      ],
      workOrder: [
        {
          required: true,
          message: "Please select work order",
          trigger: isEdit.value ? ["blur"] : ["blur", "change"],
        },
      ],
      fleet: [
        {
          required: true,
          message: "Please select Fleet",
          trigger: isEdit.value ? ["blur"] : ["blur", "change"],
        },
      ],
      workOrderTaskId: [
        {
          required: true,
          message: "Please select Task",
          trigger: isEdit.value ? ["blur"] : ["blur", "change"],
        },
      ],
      clientName: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter client name",
          trigger: ["blur", "change"],
        },
      ],
      location: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter location",
          trigger: ["blur", "change"],
        },
      ],
      manufacturer: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter manufacturer",
          trigger: ["blur", "change"],
        },
      ],
      model: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter manufacturer",
          trigger: ["blur", "change"],
        },
      ],
      vehRegNo: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter Veh. Reg. No",
          trigger: ["blur", "change"],
        },
      ],
      serialNo: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter Serial no",
          trigger: ["blur", "change"],
        },
      ],
      SMUHour: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter SMU Hours",
          trigger: ["blur", "change"],
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value <= 0) {
              callback(new Error("Please enter correct SMU hours"));
            } else {
              callback();
            }
          },
          trigger: ["blur", "change"],
        },
      ],
      travelKMS: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter Travel KMs",
          trigger: ["blur", "change"],
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value <= 0) {
              callback(new Error("Please enter correct travel KMs"));
            } else {
              callback();
            }
          },
          trigger: ["blur", "change"],
        },
      ],
      fieldServiceNote: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter field service note",
          trigger: ["blur", "change"],
        },
      ],
      customerEmail: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter customer email",
          trigger: ["blur", "change"],
        },
        {
          type: "email",
          message: "Please input correct email",
          trigger: ["blur", "change"],
        },
      ],
      isCustomerAcceptance: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please sign",
          trigger: "click",
        }
      ],
      isMechanicalAcceptance: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please sign",
          trigger: "click",
        }
      ],
      customerAcceptanceName: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter customer acceptance name",
          trigger: ["blur", "change"],
        }
      ]
    }));
    const actionTimeSheet = computed(() => store.getters["dashboard/action"]);
    const dropdownSite = ref<Site[]>([]);
    const dropdownFleet = ref<Equipment[]>([]);
    const dropdownWorkOrder = ref<WorkOrder[]>([]);
    const dropdownWorkOrderTask = ref<WorkOrderTask[]>([]);
    const store = useStore();
    const loadingDropDownSite = ref(false);
    const loadingDropDownFleet = ref(false);
    const loadingDropDownWorkOrder = ref(false);
    const loadingDropDownWorkOrderTask = ref(false);
    const timeSheetStatus = ref<number>(props.data?.timeSheetStatus ?? 0);
    const allowInputFiledService = computed(() => {
      const user = store.getters["auth/currentUser"];
      return user.showFieldService;
    });
    const sigOption = computed(() => {
      return {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      };
    });
    const signatureMechanical = ref<any>(null);
    const signatureCustomer = ref<any>(null);

    const signatureCustomerTemp = ref<boolean>(false)
    const signatureMechanicalTemp = ref<boolean>(false)

    const allowUpdate = ref<boolean>(props.allowUpdate ?? true);
    const refTimeSheet = ref<InstanceType<typeof StartTimeSheet> | null>();
    onBeforeMount(async () => {
      await initDropdown(["Site", "Fleet", "WorkOrder", "WorkOrderTask"])
    });
    onMounted(async () => {
      const getBase64FromUrl = async (url: string) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
          }
        });
      }
      if (props.isEdit) {
        allowUpdate.value = props.allowUpdate
        taskInfo.value.jobDescription = props.data?.jobDescription || "";
        taskInfo.value.startTime.hours = props.data?.hours;
        taskInfo.value.startTime.mins = props.data?.mins;
        if (props.data?.additionInfo) {
          taskInfo.value.additionInfo = true;
          taskInfo.value.TimesheetAdditionalId =
            props.data?.additionInfo.TimesheetAdditionalId;
          taskInfo.value.clientName = props.data?.additionInfo.CustomerName;
          taskInfo.value.location = props.data?.additionInfo.Locations;
          taskInfo.value.manufacturer =
            props.data?.additionInfo.Manufacturer;
          taskInfo.value.model = props.data?.additionInfo.Model;
          taskInfo.value.vehRegNo = props.data?.additionInfo.Rego;
          taskInfo.value.serialNo = props.data?.additionInfo.SerialNumber;
          taskInfo.value.SMUHour = props.data?.additionInfo.Smureading;
          taskInfo.value.travelKMS = props.data?.additionInfo.TravelKms;
          taskInfo.value.customerEmail =
            props.data?.additionInfo.CustomerEmail;
          taskInfo.value.fieldServiceNote =
            props.data?.additionInfo.AdditionalInfo;
          taskInfo.value.customerAcceptanceName =
            props.data?.additionInfo.CustomerAcceptanceName;
          taskInfo.value.mechanicalAcceptance = props.data?.additionInfo.WorkerSignature;
          taskInfo.value.customerAcceptance = props.data?.additionInfo.CustomerSignature;
          await new Promise((f) => setTimeout(f, 100));
          if (props.data?.additionInfo.WorkerSignature) {
            const base64 = !!taskInfo.value.mechanicalAcceptance && taskInfo.value.mechanicalAcceptance.length  > 200 ? `${taskInfo.value.mechanicalAcceptance}` : await getBase64FromUrl(`${imageEndpoint}/${taskInfo.value.mechanicalAcceptance}`)
            signatureMechanical.value.fromDataURL(base64)
            taskInfo.value.isMechanicalAcceptance="change";
          }
          if (props.data?.additionInfo.CustomerSignature) {
            const base64 = !!taskInfo.value.customerAcceptance && taskInfo.value.customerAcceptance.length > 200 ? `${taskInfo.value.customerAcceptance}` : await getBase64FromUrl(`${imageEndpoint}/${taskInfo.value.customerAcceptance}`)
            signatureCustomer.value.fromDataURL(base64);
            taskInfo.value.isCustomerAcceptance="change";
          }
        }
        if (props?.data?.workOrderTaskId) {
          store
            .dispatch("profile/actGetWorkOrderTaskById", {
              id: props.data?.workOrderTaskId,
              expand: 'WorkOrder($expand=Equipment)'
            })
            .then(async (result) => {
              dropdownWorkOrderTask.value.push({
                WorkOrderTaskId: result.WorkOrderTaskId,
                Description: result.Description,
                Name: result.Name,
                FullName: `${result.Name} ${result.Description}`,
                WorkOrderId: result.WorkOrderId,
              });
              taskInfo.value.workOrderTaskId = props.data?.workOrderTaskId;
              if (result.WorkOrder) {
                taskInfo.value.workOrder = result.WorkOrder.WorkOrderId;
                taskInfo.value.workOrderDescription =
                  result.WorkOrder.Description;
                dropdownWorkOrder.value.push({
                  WorkOrderId: result.WorkOrder.WorkOrderId,
                  EquipmentId: result.WorkOrder.EquipmentId,
                  Name: result.WorkOrder.Name,
                  Description: result.WorkOrder.Description,
                  FullName: `${result.Name} ${result.Description}`,
                });
                if (result.WorkOrder.Equipment) {
                  await searchDropDownSite("");
                  const site = dropdownSite.value.find(site => site.Location === result.WorkOrder.Equipment.Location);
                  taskInfo.value.site = `${site?.Location}-${site?.SiteId}`;
                  dropdownFleet.value = await store.dispatch("profile/actGetDropDownFleet", {
                    filter: site?.Location
                  });
                  taskInfo.value.fleet = result.WorkOrder.Equipment.EquipmentId;
                  dropdownSite.value.push({
                    ...result.WorkOrder.Equipment,
                  });
                }
              }
            });
        }
        if(props?.data?.siteId && !props?.data?.workOrderTaskId) {
          await searchDropDownSite("");
          const site = dropdownSite.value.find(site => site.SiteId === props?.data?.siteId);
          taskInfo.value.site = `${site?.Location}-${site?.SiteId}`
          await selectSite(taskInfo.value.site, true);
        }
        if(props?.data?.fleetId && !props?.data?.workOrderTaskId) {
          await selectFleet(props?.data?.fleetId, true)
        }
        if(props?.data?.workOrderId && !props?.data?.workOrderTaskId) {
          await selectWorkOrder(props?.data?.workOrderId, true)
        }
      }
    });

    const initDropdown = async (includeDropdown: string[]) =>{
      if(props.isEdit && !store.state.dashboard.isEditAddTask){
        if(includeDropdown.includes("Site")){
        loadingDropDownSite.value = true
        dropdownSite.value = await store
        .dispatch("profile/actGetDropDownSite", {
          filter: null,
          limit: 20
        });
        loadingDropDownSite.value = false
      }
      
      if(includeDropdown.includes("WorkOrder")){
        loadingDropDownWorkOrder.value = true
        dropdownWorkOrder.value  = await store.dispatch("profile/actGetDropDownWorkOrder",{
          filter: props?.data?.fleetId
          }
        );
        loadingDropDownWorkOrder.value = false
      }
      if(includeDropdown.includes("WorkOrderTask")){
        loadingDropDownWorkOrderTask.value = true
        dropdownWorkOrderTask.value = await store.dispatch("profile/actGetDropDownWorkOrderTask",{
          filter: props?.data?.workOrderId,
        });
        loadingDropDownWorkOrderTask.value = false
      }
      } else {}
    }

    const searchDropDownSite = async (query?: string) => {
      loadingDropDownSite.value = true;
      dropdownSite.value = await store.dispatch("profile/actGetDropDownSite", {
        filter: query,
      });
      loadingDropDownSite.value = false;
    };

    const searchDropDownFleet = async (query: string) => {
      loadingDropDownFleet.value = true;
      dropdownFleet.value = await store.dispatch("profile/actGetDropDownFleet", {
        search: query,
        filter: taskInfo.value?.site?.split("-")[0]
      });
      loadingDropDownFleet.value = false;
    };

    const validFormData = async () => {
      await refForm.value?.validate(async (valid: any, fields: any) => {
        // validData
        const validTimeSheet = refTimeSheet.value!.validData();
        const validData = valid && !validTimeSheet;
        if (validData) {
          const dataSubmit = {
            ... _.omit(taskInfo.value,['isCustomerAcceptance,isMechanicalAcceptance']),
            taskType: TASK_TYPES.Rental,
            taskId: props.data?.taskId,
            valid: validData,
          };
          // store.commit("SET_LOADING", true);
          if (validData) {
            if (signatureCustomerTemp.value) {
            const signatureCustomerPath = await uploadSinature(signatureCustomer.value?.save('image/png'))
            dataSubmit.customerAcceptance = signatureCustomerPath.image
            }

            if (signatureMechanicalTemp.value) {
              const signatureMechanicalPath = await uploadSinature( signatureMechanical.value?.save('image/png'))
              dataSubmit.mechanicalAcceptance = signatureMechanicalPath.image
            }
          }
          ctx.emit("submit", dataSubmit);
        } else {
          const dataSubmit = {
            ...taskInfo.value,
            taskType: TASK_TYPES.Rental,
            taskId: props.data?.taskId,
            valid: validData,
          };
          ctx.emit("submit", dataSubmit);
        }
      });
    };
    const selectSite = async (value?: string, isEdit: boolean = false) => {
      if(!isEdit) {
        taskInfo.value.fleet = undefined;
        taskInfo.value.workOrder = undefined;
        taskInfo.value.workOrderTaskId = undefined;
        taskInfo.value.workOrderDescription = "";
        taskInfo.value.site = value;
      }
      isLoadedFleet.value = false
    };

    const selectFleet = async (value: any, isEdit = false) => {
      if(!isEdit) {
        taskInfo.value.workOrder = undefined;
        taskInfo.value.workOrderTaskId = undefined;
        taskInfo.value.workOrderDescription = "";
      }
      taskInfo.value.fleet = value;
      isLoadedWorkOrder.value = false;
      isLoadedSite.value = false;
      isLoadedFleet.value = false;

      const selectedItem = dropdownFleet.value.find(
        (i: any) => i.EquipmentId === value
      )
      if(selectedItem){
        const fleetItem = await store.dispatch(
          "profile/actGetFleetById",
          {
            id: selectedItem?.EquipmentId,
            expand: 'Site'
          }
        );
        if(fleetItem){
          taskInfo.value.site = `${fleetItem.Site?.Location}-${fleetItem.Site?.SiteId}`;
          const isObjectInArray = dropdownSite.value.find(item => item.SiteId === fleetItem.Site.SiteId);
          if (!isObjectInArray) {
            dropdownSite.value.push({
              ...fleetItem.Site,
            });
          }
        }
      }
    };

    const selectWorkOrder = async (value: any, isEdit = false) => {
      isLoadedWorkOrderTask.value = false
      const selectedItem = dropdownWorkOrder.value.find(
        (i: any) => i.WorkOrderId === value
      )
      taskInfo.value.workOrderDescription = selectedItem?.Description;
      isLoadedWorkOrder.value = false
      if(!isEdit) {
        taskInfo.value.workOrderTaskId = undefined;
      }
      taskInfo.value.workOrder = value
      dropdownWorkOrderTask.value = await store.dispatch(
        "profile/actGetDropDownWorkOrderTask",
        {
          filter: value,
        }
      );
      if(selectedItem){
        const workOrderItem = await store.dispatch(
          "profile/actGetWorkOrderById",
          {
            id: selectedItem?.WorkOrderId,
            expand: 'Equipment($expand=Site)'
          }
        );

        taskInfo.value.fleet = selectedItem?.EquipmentId as any;
        if(!dropdownFleet.value.find((e)=> e.EquipmentId === workOrderItem.Equipment.EquipmentId)){
          dropdownFleet.value.push({
            Name: workOrderItem.Equipment.Name,
            EquipmentId: workOrderItem.Equipment.EquipmentId,
          });
        }

        taskInfo.value.site = `${workOrderItem.Equipment.Site?.Location}-${workOrderItem.Equipment.Site?.SiteId}`;
        if(!dropdownSite.value.find((e)=> e.Location === workOrderItem.Equipment.Site.Location)){
          dropdownSite.value.push({
            ...workOrderItem.Equipment.Site,
          });
        }
      }
    };

    const clearField = async (value:any) => {
    }

    const isLoadedSite = ref<boolean>(false);
    const isLoadedFleet = ref<boolean>(false);
    const isLoadedWorkOrder = ref<boolean>(false);
    const isLoadedWorkOrderTask = ref<boolean>(false);

    const handleLoadSite = async (value: boolean) => {
      if (value && !isLoadedSite.value) {
        try {
          loadingDropDownSite.value = true
          if (taskInfo.value.fleet) {
            dropdownSite.value = await store.dispatch("profile/actGetDropDownSite", {
              filter: null,
              limit: 20
            });  
            const fleetItem = await store.dispatch("profile/actGetFleetById", {
              id: taskInfo.value.fleet,
              expand: 'Site'
            });
            if (fleetItem) {
              const isObjectInArray = dropdownSite.value.find(item => item.SiteId === fleetItem.Site.SiteId);
              if (!isObjectInArray) {
                dropdownSite.value.push({
                  ...fleetItem.Site,
                });
              }
            }
          } else {
            dropdownSite.value = await store.dispatch("profile/actGetDropDownSite", {
              filter: null,
              limit: 20
            });  

          }
          isLoadedSite.value = true
          loadingDropDownSite.value = false
        } catch (error) {}
      }
    }

    const handleLoadFleet = async (value: boolean) => {
      if (value && !isLoadedFleet.value) {
        try {
          loadingDropDownFleet.value = true
          if (taskInfo.value.site) {
            dropdownFleet.value = await store.dispatch("profile/actGetDropDownFleet", { filter: taskInfo.value.site.split("-")[0] });
          } else {
            dropdownFleet.value = await store.dispatch("profile/actGetDropDownFleet", { search: '', limit: 20 });
          }
          isLoadedFleet.value = true
          loadingDropDownFleet.value = false
        } catch (error) {}
      }
    }

    const handleLoadWorkOrder = async (value: boolean) => {
      if (value && !isLoadedWorkOrder.value) {
        try {
          loadingDropDownWorkOrder.value = true
          if (taskInfo.value.fleet) {
            dropdownWorkOrder.value = await store.dispatch("profile/actGetDropDownWorkOrder", { filter: taskInfo.value.fleet })
          } else {
            dropdownWorkOrder.value = await store.dispatch("profile/actGetDropDownWorkOrder", { search: '', limit: 20 })
          }
          isLoadedWorkOrder.value = true
          loadingDropDownWorkOrder.value = false
        } catch (error) {}
      }
    }
    
    const handleLoadWorkOrderTask = async (value: boolean) => {
      if (value && !isLoadedWorkOrderTask.value) {
        try {
          loadingDropDownWorkOrderTask.value = true
          if(taskInfo.value.workOrder) {
            dropdownWorkOrderTask.value = await store.dispatch("profile/actGetDropDownWorkOrderTask", {
              filter: taskInfo.value.workOrder,
            });
          } else {
            dropdownWorkOrderTask.value = []
          }
          isLoadedWorkOrderTask.value = true
          loadingDropDownWorkOrderTask.value = false
        } catch (error) {}
      }
    }

    const selectWorkOrderTask = async (value: any) => {};
    
    const handleInputTimeSheet = (data: any) => {
      taskInfo.value.startTime.hours = data.hours;
      taskInfo.value.startTime.mins = data.minutes;
      taskInfo.value.TimesheetLineMinutes = data.hours * 60 + data.minutes;
      ctx.emit("updateTimeSheet", {
        taskId: taskId.value,
        hours: data.hours,
        mins: data.minutes,
      });
    };
    const handleFilterWorkOrder = async (search: String) => {
      dropdownWorkOrder.value = await store.dispatch(
        "profile/actGetDropDownWorkOrder",
        taskInfo.value.fleet?{
          filter: taskInfo.value.fleet,
          search,
        }:{search, ...WorkOrderQueryBySite.value}
      );
    };
    const handleFilterWorkOrderTask = async (search: String) => {
      dropdownWorkOrderTask.value = await store.dispatch(
        "profile/actGetDropDownWorkOrderTask",
        taskInfo.value.workOrder?{
          filter: taskInfo.value.workOrder,
          search,
        }:WorkOrderTaskQueryByFleet?{search, ...WorkOrderTaskQueryByFleet.value}:{search, ...WorkOrderTaskQueryBySite.value}
      );
    };

    const handleSignatureClick = (event: any, type: "customer" | "mechanical") => {
      // Xử lý sự kiện khi người dùng tương tác với vùng ký tên
      if (type == "customer") {
        signatureCustomerTemp.value = true
        taskInfo.value.isCustomerAcceptance = "change";
      } else {
        signatureMechanicalTemp.value = true
        taskInfo.value.isMechanicalAcceptance = "change";
      }
    };

    const handleClearSignature = (type: "customer" | "mechanical") => {
      if (type == "customer") {
        taskInfo.value.customerAcceptance = '';
        signatureCustomer.value.clear()
        signatureCustomerTemp.value = false
        taskInfo.value.isCustomerAcceptance = "";
      } else {
        taskInfo.value.mechanicalAcceptance = '';
        signatureMechanical.value.clear()
        signatureMechanicalTemp.value = false
        taskInfo.value.isMechanicalAcceptance = "";
      }
    }

    const convertBase64ToBlob = (image: any) => new Promise((resolve, reject) => {
      return fetch(image)
        .then(res => resolve(res.blob()))
        .then(err => reject(err))
    });

    const uploadSinature = async (imageBase64: string) => {
      var formData = new FormData();
      const blob: any = await convertBase64ToBlob(imageBase64);
      const file = new File([blob], "avatar.png", { type: "image/png" });
      formData.append('img', file);
      return await store.dispatch("dashboard/uploadSignature", formData)
    }

    watch(
      actionTimeSheet,
      async (newValue) => {
        if(newValue == Actions.SAVE.toString() || newValue == Actions.UPDATE.toString()) {
          const dataSubmit = {
            ... _.omit(taskInfo.value,['isCustomerAcceptance,isMechanicalAcceptance']),
            taskType: TASK_TYPES.JobCost,
            taskId: props.data?.taskId,
            valid: true,
          }
          store.commit("SET_LOADING", true);
          if (signatureCustomerTemp.value) {
            const signatureCustomerPath = await uploadSinature(signatureCustomer.value?.save('image/png'))
            dataSubmit.customerAcceptance = signatureCustomerPath.image
          }

          if (signatureMechanicalTemp.value) {
            const signatureMechanicalPath = await uploadSinature( signatureMechanical.value?.save('image/png'))
            dataSubmit.mechanicalAcceptance = signatureMechanicalPath.image
          }
          ctx.emit('submit', dataSubmit)
        }
        else if (newValue == Actions.CONFIRM.toString() || newValue == Actions.UPDATE_CONFIRM) {
          validFormData()
        }
      },
    )

    watch(props, () => {
      errors.value = props.errors
      taskInfo.value.startTime.hours = props?.data?.hours ?? 0;
      taskInfo.value.startTime.mins = props?.data?.mins ?? 0;
    });

    watch(taskInfo.value, () => {
      const valueStr = JSON.stringify(taskInfo.value)
      
      if (defaultValueStr === valueStr) {
        ctx.emit("changeTouched", false);
      } else {
        ctx.emit("changeTouched", true);
      }
    })


    return {
      refForm,
      rules,
      taskInfo,
      dropdownSite,
      loadingDropDownSite,
      loadingDropDownFleet,
      loadingDropDownWorkOrder,
      loadingDropDownWorkOrderTask,
      searchDropDownSite,
      searchDropDownFleet,
      selectSite,
      dropdownFleet,
      selectFleet,
      dropdownWorkOrder,
      selectWorkOrder,
      selectWorkOrderTask,
      dropdownWorkOrderTask,
      actionTimeSheet,
      sigOption,
      signatureMechanical,
      signatureCustomer,
      allowUpdate,
      allowInputFiledService,
      taskId,
      handleInputTimeSheet,
      refTimeSheet,
      timeSheetStatus,
      handleFilterWorkOrder,
      handleFilterWorkOrderTask,
      errors,
      index: props?.data?.index,
      handleSignatureClick,
      handleClearSignature,
      clearField,
      handleLoadWorkOrder,
      handleLoadWorkOrderTask,
      handleLoadFleet,
      handleLoadSite,
      isLoadedSite,
      isLoadedFleet,
      isLoadedWorkOrder,
      isLoadedWorkOrderTask,
      isEdit: props.isEdit,
    };
  },
});
</script>
<style lang="scss">
.work-order-description {
  label {
    line-height: 20px !important;
  }
}
.dark {
  .el-select__icon {
    color: white !important;
  }
}

.el-select__icon {
  color: #333333 !important;
}

.el-form-item--default .el-form-item__label {
  display: inline-block;
}

.is-error {
  .sign-pad {
    border-color: #f56c6c !important;
  }
}

</style>