<template>
  <el-dialog v-model="dialogVisible" :title=title width="640px" class="p-10 user-form" :before-close="handleClose"
    :close-on-click-modal="false" top="40px">
    <el-form ref="ruleFormRef" :model="user" :rules="rules" label-width="160px" size="default" status-icon
      require-asterisk-position="right" label-position="left" :validate-on-rule-change="false">
      <el-form-item label="User Type" prop="UserType">
        <el-select ref="refuserType" v-model="user.UserType" placeholder="User Type" size="large" class="w-full"
          filterable clearable v-if="currentUser.userType == USER_TYPE_USER_ADMINISTRATOR">
          <el-option v-for="item in userTypeListRoleUser" :key="item.id" :label="item.value" :value="item.id" />
        </el-select>
        <el-select ref="refuserType" v-model="user.UserType" placeholder="User Type" size="large" class="w-full"
          filterable clearable v-else>
          <el-option v-for="item in userTypeList" :key="item.id" :label="item.value" :value="item.id" />
        </el-select>
      </el-form-item>
      <div class="flex gap-2">
        <el-form-item label="GP Employee ID" prop="GPEmployeeId" class="flex-1">
          <el-input v-model="user.GPEmployeeId" placeholder="Look Up GP Employee ID" size="large" disabled/>
        </el-form-item>
        <el-button class="btn-submit" @click="clickGPEmployees">Look up</el-button>
      </div>
      <el-form-item label="First Name" prop="FirstName" autofocus>
        <el-input v-model="user.FirstName" placeholder="First Name" size="large" required />
      </el-form-item>
      <el-form-item label="Last Name" prop="LastName">
        <el-input v-model="user.LastName" placeholder="Last Name" size="large" />
      </el-form-item>
      <el-form-item label="GP Pay Cycle" prop="GPPayCycle">
        <el-select ref="refuserType" v-model="user.GPPayCycle" placeholder="GP Pay Cycle" size="large" class="w-full" filterable clearable disabled>
          <el-option v-for="item in gpPayCycleGroup" :key="item.id" :label="item.value" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="GP Staff ID" prop="GPStaffNumber">
        <el-input v-model="user.GPStaffNumber" placeholder="GP Staff ID" size="large" disabled/>
      </el-form-item>
      <el-form-item label="Email" prop="Email" required>
        <el-input v-model="user.Email" placeholder="Email" size="large" />
      </el-form-item>
      <el-form-item
        v-if="user.UserType != USER_TYPE.USER_ADMINISTRATOR"
        label="Submitter To Approval Group" prop="ApprovalGroupId" class="label-break">
        <el-select ref="approveGroup" v-model="user.ApprovalGroupId" placeholder="Submitter To Approval Group"
          size="large" class="w-full" filterable clearable>
          <el-option v-for="item in listApprovalGroup" :key="item.ApprovalGroupId" :label="item.Name"
            :value="item.ApprovalGroupId ? item.ApprovalGroupId : ''" />
        </el-select>
      </el-form-item>
      <el-form-item label="Default Start Time">
        <TimePicker :hours="startDate.hours" :minutes="startDate.minutes" :isAm="startDate.isAm" @submit="submit">
        </TimePicker>
      </el-form-item>
      <!-- <el-form-item 
        v-if="user.UserType == USER_TYPE.STAFF"
        label="Great Plains User">
        <el-input
          v-model="user.GreatPlainsUser"
          placeholder="Great Plains User"
          size="large"
        />
      </el-form-item> -->
      <el-form-item label="Allow Timesheet Creation" class="label-break" prop="serviceInfo" v-if="user.UserType == USER_TYPE.APPROVER">
        <div class="flex w-full">
          <el-switch v-model="user.AllowTimesheetCreation" size="large"></el-switch>
        </div>
      </el-form-item>
      <el-form-item label="Pay Location" prop="PayLocation">
        <el-select v-model="user.PayLocation" placeholder="Pay Location" class="w-[400px]" size="large" filterable @clear="clearPayLocation"
          remote clearable :remote-show-suffix="true" popper-class="select-custom-popper w-[374px]" @click="searchDropDownPayLocation('')" 
          :remote-method="searchDropDownPayLocation" :loading="loadingPayLocation">
          <el-option v-for="item in dropdownPayLocation" :key="item.Name"
            :label="item.Name" :value="item.Name">
            <span style="float: left">{{ item.Name }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Visible Task Type" prop="TaskType" v-if="user.UserType != USER_TYPE.USER_ADMINISTRATOR">
        <el-select ref="TaskType" v-model="user.TaskType" placeholder="Visible Task Type" size="large" class="w-full"
          default-first-option filterable>
          <el-option v-for="item in lsTaskType" :key="item.Value" :label="item.Label" :value="item.Value" />
        </el-select>
      </el-form-item>
      <el-form-item label="Show Field Service" prop="serviceInfo" v-if="user.UserType != USER_TYPE.USER_ADMINISTRATOR">
        <div class="flex w-full">
          <el-switch v-model="user.ShowFieldService" size="large"></el-switch>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose()" class="btn-cancel">Cancel
        </el-button>
        <el-button class="btn-submit" :disabled="!isEnableSave" @click="insert(ruleFormRef)">
          <span>Create User</span>
        </el-button>
      </span>
    </template>
  </el-dialog>

  <div>
    <el-dialog class="popup-gp-employee" v-model="popupGPEmployees" width="96vw"
    :style="{ 'max-height': '94vh', 'min-height': '92vh' }" top="40px"
    :title="'GP Employee'">
      <PopupEmployee @selectItemGPEmployees="selectItemGPEmployees" @closeGPGPEmployees="closeGPGPEmployees" :popupGPEmployees="popupGPEmployees"/>
    </el-dialog>
</div>
  
  
</template>
<script lang="ts">
import { computed, defineComponent, reactive, ref, watch, onBeforeMount, inject, shallowRef, h } from "vue";
import { FormInstance, FormRules, valueEquals } from "element-plus";
import TimePicker from "./TimePicker.vue";
import { useStore } from "vuex";
import { MESSAGE_CODES, TASK_TYPE_LISTS } from "core/constants";
import { USER_TYPE } from "core/constants";
import IconShowHidePwd from "components/IconShowHidePwd.vue";
import { useRouter } from 'vue-router';
import { loginRoute } from "router/page-routes";
import { JobDivisionPayLocation, PayLocation } from "modules/admin/users/store/types";
import _ from 'lodash';
import PopupEmployee from "../modules/admin/users/views/components/PopupEmployee.vue";
import { formatTime } from '../utils/index';
import { USER_TYPE_USER_ADMINISTRATOR } from 'core/constants';

interface timeObject {
  hours: number,
  minutes: number,
  isAm: boolean,
}

export default defineComponent({
  name: "UserForm",
  props: {
    title: {
      type: String,
    },
  },
  components: {
    TimePicker,
    IconShowHidePwd,
    PopupEmployee
  },
  setup(props) {

    const store = useStore();
    const router = useRouter();
    const currentUser = computed(() => store.getters['auth/currentUser'])

    const isOpenPopupUserForm = computed(() => store.state.isOpenPopupUserForm);
    const ruleFormRef = ref<FormInstance>();
    const dialogVisible = ref<boolean>(false);
    const lsApprovalGroup = computed<ApprovalGroupModel[]>(() => store.getters['approvalGroup/lsGroupApproval'].filter((e: ApprovalGroupModel) => e.Status === 1));
    
    const listApprovalGroup = ref<ApprovalGroupModel[]>([])

    const initListApprovalGroup = async () => {
      const list = await store.dispatch("user/getAprrovalgroup");
      listApprovalGroup.value = list
    }

    watch(lsApprovalGroup, () => {
      initListApprovalGroup()
    })


    const lsTaskType = ref<any[]>(TASK_TYPE_LISTS);
    const loading = ref(false);
    const isEnableSave = ref(true);
    const dropdownPayLocation = ref<PayLocation[]>([])
    const loadingPayLocation = ref<boolean>(false)

    const selectedJobDivisionPayLocation = ref("")
    const notify = inject('notify', (type: string, message: string) => ({}));
    const paramSearch = computed(() => store.getters['user/userParam']);
    const userCount = computed(() => store.getters['user/userCount']);
    const popupGPEmployees = ref(false);

    const title = props.title;
    const startDate = ref<timeObject>({
      hours: 8,
      minutes: 30,
      isAm: true,
    });
    const user = ref<UserModel>({
      UserType: 0,
      ShowFieldService: true,
      TaskType: 0,
      AllowTimesheetCreation: false
    });

    const defaultStartTime = computed(
      () => {
        const defaultStartTime = formatTime(store.getters["setting/settings"].DefaultStartTime)
        return _.omit(defaultStartTime, 'hour')
      }
    );
    const requiredGPEmployeeId = ref(true);
    const requiredApprovalGroupId = ref(true);
    const requiredPaylocation = ref(true)
    const requiredTaskType = ref(true);
    const isClickInsert = ref(false);

    watch(user.value, () => {
      if (user.value.UserType === 0) {
        requiredGPEmployeeId.value = true
        requiredApprovalGroupId.value = true
        requiredPaylocation.value = true
        requiredTaskType.value = true
      } else {
        requiredGPEmployeeId.value = false
        requiredApprovalGroupId.value = false
        requiredTaskType.value = false
      }

      if (user.value.UserType == 1) {
        if (user.value.AllowTimesheetCreation) {
          requiredGPEmployeeId.value = true
          requiredApprovalGroupId.value = true
          requiredPaylocation.value = true
          requiredTaskType.value = true
        } else {
          requiredPaylocation.value = false
          requiredGPEmployeeId.value = false
          requiredApprovalGroupId.value = false
          requiredTaskType.value = false
        }
      }

      if (user.value.UserType == 2 || user.value.UserType == 3) {
        requiredPaylocation.value = false
      }
      if (isClickInsert.value) {
        if (!ruleFormRef.value) return
        ruleFormRef.value.validate();
      }
    })

    const rules = reactive<any>({
      GPEmployeeId: [
        {
          required: requiredGPEmployeeId,
          message: "Please enter GP Employee ID",
          trigger: 'change',
        }
      ],
      GPPayCycle: [
        {
          required: false,
        }
      ],
      GPStaffNumber: [
        {
          required: false,
        }
      ],

      UserType: [
        {
          required: true,
          message: "Please select User Type",
          trigger: 'change',
        },
      ],
      FirstName: [
        {
          required: true,
          message: "Please enter First Name",
          trigger: ["blur", "change"],
        },
      ],
      LastName: [
        {
          required: true,
          message: "Please enter Last Name",
          trigger: ["blur", "change"],
        },
      ],
      PayLocation: [
        {
          required: requiredPaylocation,
          message: "Please enter Pay Location",
          trigger: "blur",
        },
      ],
      Email: [
        {
          required: true,
          message: "Please enter email",
          trigger: ["blur"],
        },
        {
          type: "email",
          message: "Please input correct email",
          trigger: ["blur", "change"],
        },
      ],
      ApprovalGroupId: [
        {
          required: requiredApprovalGroupId,
          message: "Please select Approval Group",
          trigger: "change",
        },
      ],
      TaskType: [
        {
          required: requiredTaskType,
          message: "Please select Visible Type",
          trigger: "change",
        },
      ],
    });

    const userTypeList = [
      {
        id: 0,
        value: "Staff"
      },
      {
        id: 1,
        value: "Approver"
      },
      {
        id: 2,
        value: "User_Administrator"
      },
      {
        id: 3,
        value: "System_Administrator"
      },
    ];
    
    const userTypeListRoleUser = [
      {
        id: 0,
        value: "Staff"
      },
      {
        id: 1,
        value: "Approver"
      },
      {
        id: 2,
        value: "User_Administrator"
      },
    ];

    //fake data
    const gpPayCycleGroup: any[] = [
    {
        id: 0,
        value: "None"
      },
      {
        id: 1,
        value: "Rental"
      },
      {
        id: 2,
        value: "Workshops"
      },
    ];

    const searchDropDownPayLocation = async (query: string) => {
      loadingPayLocation.value = true;
      const listPaylocation :PayLocation[]  = await store.dispatch("user/dropPayLocation", {
        search: query
      });
      dropdownPayLocation.value = listPaylocation
      loadingPayLocation.value = false;
    }
    const handleClose = () => {
      // formEl: FormInstance | undefined
      isClickInsert.value = false
      if (!ruleFormRef.value) return
      ruleFormRef.value.resetFields();
      store.commit('SET_OPEN_POPUP_USER_FORM', false)
      clearPayLocation()
      user.value.ShowFieldService = true
      user.value.AllowTimesheetCreation = false
    }

    const clearPayLocation = () => {
      selectedJobDivisionPayLocation.value = ""
    }

    watch(isOpenPopupUserForm, () => {
      startDate.value = defaultStartTime.value
      dialogVisible.value = isOpenPopupUserForm.value
    })

    
    const getApprovalGroup = async (query: any, limit?: number) => {
      let params = {};
      store.commit('SET_LOADING', true)
      store.dispatch("user/appovalGroupList", params).then((data) => {
        store.commit("approvalGroup/SET_LIST_APPROVAL", data);
        loading.value = false;
      }).catch(err => {
        if (err.status === 401) {
          router.push(loginRoute);
        }
      }).finally(() => {
        store.commit('SET_LOADING', false)
      })

    };
    const getStrTime = (value: any) => {
      let str = "";
      if (value) {
        const minute = (value.minutes ? value.minutes : 0).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
        str = `${value.hours}:${minute}`;
      }
      return str;

    };
    const insert = async (formEl: FormInstance | undefined) => {
      try {
        isClickInsert.value = true
        rules.GPEmployeeId[0].required = user.value.UserType === 0 || user.value.UserType === 1 && user.value.AllowTimesheetCreation;
        let validForm = true;
        isEnableSave.value = true;
        if (!formEl) return
        await formEl.validate((valid, fields) => {
          validForm = valid;

        })

        if (currentUser.value.userType == USER_TYPE_USER_ADMINISTRATOR && user.value.UserType == 3) {
          notify('error', 'User admins are not be able to create system administrators.');
          return;
        }
        
        if (!validForm) {
          isEnableSave.value = true;

        } else {
          //Check exit email
          const isExistEmail = await store.dispatch("user/isExistEmail", user.value.Email?.toString());
          if (isExistEmail) {
            notify('error', MESSAGE_CODES.MSG_EMAIL_EXIST);
            // store.commit("SET_LOADING", false);
            return;
          }

          store.commit("SET_LOADING", true);
          let time = startDate.value
          if(time.hours === 12 && time.isAm) {
            time.hours = 0
          }      
          const strTime = `${time.hours}:${(time.minutes ? time.minutes : 0).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:00.00`;

          if (user.value.UserType != USER_TYPE.APPROVER) {
            user.value.AllowTimesheetCreation = true
          }

          user.value.DefaultStartTime = strTime;
          user.value.Password = 'Tuba66134';
          user.value.PayLocation = user.value.PayLocation ? user.value.PayLocation : ''
          store.dispatch("user/insert", user.value,).then((response) => {
            handleClose();
            if ("Users" == router.currentRoute.value.name) {
              refeshMainPage();
              notify('success', MESSAGE_CODES.MSG_SAVE_SUCCESS);
            } else {
              store.commit("SET_LOADING", false);
              isEnableSave.value = true;
              notify('success', MESSAGE_CODES.MSG_SAVE_SUCCESS);
            }
          }).catch((error) => {
            store.commit("SET_LOADING", false);
            isEnableSave.value = true;
            if (error?.data?.value) {
              notify('error', error?.data?.value);
            }
            return;
          });
        }

      } catch (error) {
        isEnableSave.value = true;
        store.commit('SET_LOADING', false);
      }
    };

    const refeshMainPage = () => {
      store.commit('SET_LOADING', true);
      store.dispatch("user/select", paramSearch.value).then((response) => {
        store.commit('user/SET_USER_LIST', response.value);
        store.commit('user/SET_USER_COUNT', response["@odata.count"]);
        store.commit('user/SET_USER_PRAM', paramSearch.value);
        store.commit('SET_LOADING', false);
      });
    };

    const submit = (value: any) => {
      startDate.value = value
    }

    onBeforeMount(() => {
      getApprovalGroup("");
      searchDropDownPayLocation("")
    });

    const handleChangeJosCostLocation = (item: PayLocation) => {
      selectedJobDivisionPayLocation.value = item.Name 
    }

    const clickGPEmployees = async (row: any) => {
      popupGPEmployees.value = true;
    };

    const closeGPGPEmployees = () => {
      popupGPEmployees.value = false;
    }

    const selectItemGPEmployees = (employee: any) => {
      popupGPEmployees.value = false;
      if (employee) {
        user.value.GPEmployeeId = employee.EmployeeId
        user.value.FirstName = employee.FirstName
        user.value.LastName = employee.LastName
        if (employee.PayCycle === "None") {
          user.value.GPPayCycle = 0 
        } else if (employee.PayCycle === "Rental") {
          user.value.GPPayCycle = 1
        } else if (employee.PayCycle === "Workshops")  {
          user.value.GPPayCycle = 2
        }
        user.value.GPStaffNumber = employee.StaffNumber
      } else {
        user.value.GPEmployeeId = ""
        user.value.FirstName = ""
        user.value.LastName = ""
        user.value.GPPayCycle = ""
        user.value.GPStaffNumber = ""
      }
      
    }

    return {
      ruleFormRef,
      rules,
      user,
      dialogVisible,
      title,
      startDate,
      lsApprovalGroup,
      lsTaskType,
      userTypeList,
      USER_TYPE,
      paramSearch,
      userCount,
      isEnableSave,
      handleClose,
      getApprovalGroup,
      insert,
      submit,
      IconShowHidePwd,
      getStrTime,
      searchDropDownPayLocation,
      loadingPayLocation,
      dropdownPayLocation,
      handleChangeJosCostLocation,
      clearPayLocation,
      gpPayCycleGroup,
      clickGPEmployees,
      popupGPEmployees,
      selectItemGPEmployees,
      closeGPGPEmployees,
      listApprovalGroup,
      userTypeListRoleUser,
      currentUser,
      USER_TYPE_USER_ADMINISTRATOR
    };
  },
});
</script>

<style lang="scss">
.label-break {

  label,
  .el-form-item__label {
    line-height: 20px !important;

  }
}
.bg-bold-input {
  .el-input__wrapper {
    background-color: rgba(35, 31, 32, 0.1);
  }
}

.dark {
  .label-break {

    label,
    .el-form-item__label {
      line-height: 20px !important;

    }
  }
  .bg-bold-input {
    .el-input__wrapper {
      background-color: #323644;
    }
  }
}
.user-form {
  .el-dialog__header {
    display: flex;
    justify-content: flex-start;

  }
  .el-dialog__footer {
    padding-right: 0px;
    display: flex;
    justify-content: flex-end;
  }
}

</style>