import Service from "core/service";
export class NotificationService extends Service {
  fetchNotification(
    currentPage: number,
    pageSize: number,
    expand?: string,
    filter?: string,
    orderby?: string,
    count: boolean = false,
  ) {
    let params: any = {
      "$expand": "TimesheetHeader($expand=Approver, User), User, ActorUser",
      "$orderby": 'TimesheetNotificationDateTime desc'
    };
    if (expand) {
      params["$expand"] = expand;
    }
    if (filter) {
      params["$filter"] = filter;
    }
    if (orderby) {
      params["$orderby"] = orderby;
    }
    if (count) {
      params["$count"] = true;
      params["$top"] = pageSize;
      params["$skip"] = currentPage == 1 ? 0 : (currentPage - 1) * pageSize;
    }
    return this.get("/TimesheetNotification", params);
  }

  fetchCountNotiUnRead(
  ) {
    let params: any = {
      "$filter": "IsRead eq false",
      "$orderby": 'TimesheetNotificationDateTime desc'
    };
    return this.get("/TimesheetNotification/$count", params);
  }

  markAllRead(
  ) {
    return this.post("/TimesheetNotification/MarkAllRead");
  }

  updateNotification(dataUpdate: any) {
    return this.patch(`/TimesheetNotification/${dataUpdate.Id}`, {
      IsRead: dataUpdate.IsRead
    });
  }

  registerToken(data: any) {
    return this.post(`/NotificationHub`, data);
  }
}
