<template>
  <el-popover :disabled="disable" placement="bottom" @before-leave="handleBeforeLeave" :width="240" trigger="click"
    popper-class="time-picker-popper" ref="refElPopupOverInputTime">
    <template #reference>
      <div class="
          time-picker-component
          h-[40px]
          flex
          items-center
          justify-center
          min-w-[150px]
        " :class="disable ? 'time-picker-component-is-disable' : 'cursor-pointer'">
        <img src="../assets/icon_clock.svg" height="16" width="16" class="ml-[18px] mr-[18px]" />
        <span>{{ displayHour }}:</span>
            <span>{{ displayMinute }}</span>
                <span class="ml-[5px]">{{ isAm ? "AM" : "PM" }}</span>
                <el-icon class="ml-[20px] mr-[20px]">
                  <ArrowDown />
                </el-icon>
      </div>
    </template>
    <div class="p-[8px]">
      <div class="grid grid-flow-row auto-rows-max">
        <div class="font-medium text-sm mb-[8px] lable-popup-time">Enter Time</div>
        <div class="grid grid-flow-col auto-cols-max">
          <div class="mr-[8px]">
            <div class="flex flex-col">
              <div>
                <input class="h-[64px] w-[64px] font-normal text-center time-picker-component-input" type="text"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');  if(Number(this.value)>12) this.value=12;"
                  :value="displayEnterHour" onclick="this.select()"
                  @change="handleChangeHour($event)" />
              </div>
              <div class="mt-[6px] text-sm font-semibold lable-popup-time">
                <span>Hours</span>
              </div>
            </div>
          </div>
          <p class="font-normal text-center lable-popup-time" style="font-size: 36px">:</p>
          <div class="ml-[8px]">
            <div class="flex flex-col">
              <div>
                <input class="h-[64px] w-[64px] font-normal text-center time-picker-component-input" type="text"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'); if(Number(this.value)>59) this.value=59;"
                  :value="minutes" @change="handleChangeMin($event)" onclick="this.select()" />
              </div>
              <div class="mt-[6px] text-sm font-semibold lable-popup-time">
                <span>Minute</span>
              </div>
            </div>
          </div>
          <div class="grid grid-flow-row auto-rows-max ml-[12px]">
            <div style="border-radius: 4px"
              class="cursor-pointer flex items-center justify-center w-[39px] h-[28px] time-picker-component-zone" :style="{
                background: isAm ? '#ED1C2D' : '',
              }" v-on:click="onChangeIsAM('AM')">
              <p
                :class="isAm ? 'time-picker-component-zone-text-selected' : 'time-picker-component-zone-text-un-selected'">
                AM</p>
            </div>
            <div style="border-radius: 4px" class="
              cursor-pointer
               flex
               items-center
               justify-center
               w-[39px]
               h-[28px]
               mt-[8px]
               time-picker-component-zone
             " :style="{
               background: !isAm ? '#ED1C2D' : '',
             }" v-on:click="onChangeIsAM('PM')">
              <p
                :class="!isAm ? 'time-picker-component-zone-text-selected' : 'time-picker-component-zone-text-un-selected'">
                PM</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center; margin: 0px; padding-bottom: 8px; padding-top: 12px;">
      <el-button class="btn-cancel" @click="handleCancel">Cancel</el-button>
      <el-button class="btn-apply" type="primary" style="background-color: #246bfd" @click="submitTime">Apply</el-button>
    </div>
  </el-popover>
</template>
<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { ArrowDown } from "@element-plus/icons-vue";
import { useStore } from "vuex";
import { showError } from "../utils/notifications";

export default defineComponent({
  name: "TimePicker",
  props: {
    hours: Number,
    minutes: Number,
    isAm: Boolean,
    disable: Boolean,
    isInit: Boolean,
    additionValues: Object,
    changeValidTime: Boolean
  },
  components: {
    ArrowDown,
  },
  setup(props, ctx) {
    const store = useStore();
    const refElPopupOverInputTime = ref<any>(null);
    const isInit = ref<boolean>(props.isInit);
    const hoursTmp = ref<number>(props.hours ?? 0);
    const minutesTmp = ref<number>(props.minutes ?? 0);
    const hours = ref<number>(props.hours ?? 0);
    const minutes = ref<number>(props.minutes ?? 0);
    const isAm = ref<boolean>(props?.isAm);
    const isAmTmp = ref<boolean>(props?.isAm);
    const disable = ref<boolean>(props.disable);

    const displayHour = computed(() => {
      if (isInit.value) {
        return '--';
      } else {
        let hourValue = hours.value % 12 || 12;
        if (hourValue < 10) {
          return `0${hourValue}`
        } else {
          return `${hourValue}`
        }
      }
    })
  
    const displayMinute = computed(() => {
      if (isInit.value) {
        return '--'
      } else {
        if (minutes.value < 10) {
          return `0${minutes.value}`
        } else {
          return (minutes.value).toString()
        }
      }

    })
      
    const displayEnterHour = computed(() => {
      if (hours.value < 10) {
        if (hours.value == 0) {
          return 12
        } else {
          return hours.value
        }
      } else {
        if (hours.value % 12 != 0) {
          return (hours.value % 12)
        } else {
          return 12
        }
      }
    })

    const onChangeIsAM = (type: string) => {
      if (type == "AM") {
        isAm.value = true;
      }
      if (type == "PM") {
        isAm.value = false;
      }
      hours.value = hoursTmp.value;
      minutes.value = minutesTmp.value;
    };

    const submitTime = () => {
      let _hours = hoursTmp.value;
      let _minutes = minutesTmp.value;
      
      if (_hours == 0) {
        _hours = 12
      }

      if (!isAm.value) {
        _hours = (_hours % 12) + 12; // Nếu là PM, cộng 12 vào giờ (trừ trường hợp 12 PM)
      } else {
        if (_hours > 12) {
          _hours %= 12; // Nếu là AM và giờ lớn hơn 12, chuyển thành dạng 12 giờ
        } else if (_hours === 24) {
          _hours = 12; // Nếu là AM và giờ là 24, chuyển thành 12 AM
        }
      }

      let hourSubmit = _hours;

      const dataSubmit = {
        hours: hourSubmit,
        minutes: _minutes,
        isAm: isAm.value,
        strTime: `${hourSubmit < 10 ? '0' + hourSubmit : hourSubmit + ''}:${minutes.value < 10 ? '0' + minutes.value : minutes.value + ''}:00`
      }

      ctx.emit('submit', dataSubmit, props.additionValues)
      refElPopupOverInputTime.value?.hide()
    }
    
    const handleChangeHour = (event: any) => {
      hoursTmp.value = parseInt(event.target.value) || 12;
    };

    const handleChangeMin = (event: any) => {
      minutesTmp.value = parseInt(event.target.value) || 0;
    };

    const handleCancel = () => {
      hours.value = props.hours ?? 0;
      minutes.value = props.minutes ?? 0;
      isAm.value = props.isAm;
      refElPopupOverInputTime.value?.hide();
    }

    const handleBeforeLeave = () => {
      hours.value = props.hours ?? 0;
      hoursTmp.value = props.hours ?? 0;
      minutes.value = props.minutes ?? 0;
      minutesTmp.value = props.minutes ?? 0;
      isAm.value = props.isAm;
    }

    watch(props, () => {
      hours.value = props?.hours ?? 0;
      hoursTmp.value = props?.hours ?? 0;
      minutes.value = props?.minutes ?? 0;
      minutesTmp.value = props?.minutes ?? 0;
      isAm.value = props.isAm;
      isAmTmp.value = props.isAm;
      isInit.value = props.isInit;
      disable.value = props.disable;
    });

    return {
      onChangeIsAM,
      submitTime,
      minutes,
      hours,
      handleChangeHour,
      handleChangeMin,
      refElPopupOverInputTime,
      handleCancel,
      disable,
      handleBeforeLeave,
      isInit,
      isAm,
      displayHour,
      displayMinute,
      displayEnterHour,
    };
  },
});
</script>
<style scoped lang="scss">
.time-picker {
  border-radius: 6px;

  .light {
    box-shadow: 0px 8px 12px rgb(31 35 53 / 15%);
    border: 1px solid rgba(35, 31, 32, 0.1);
    background: #fff;
  }

  .dark {
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.46);
    border: 1px solid #3A4056;
    background: #262A34;
  }
}

.btn-apply {
  padding: 10px 20px;
  border: none;

  &:hover,
  &:focus {
    color: white !important;
  }
}

.btn-cancel {
  padding: 10px 20px;
}
</style>