import Service from "core/service";
import type { AxiosInstance, AxiosRequestConfig, Method, AxiosError } from 'axios'

export class ApprovalGroupService extends Service {

  insert(formData: any, options: any): Promise<any> {
    try {
      return this.post("/approvalGroup", formData, options);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }
  update(body: any, id: any): Promise<any> {
    try {
      return this.patch(`/approvalGroup/${id}`, body);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  select(currentPage: number = 1,pageSize: number = 20,selectProps: string, expand: string | null, filter: string | null, sort:{ field:string, sortOrder:string}): Promise<any> {
    try {
      let params: any = {
        $select: selectProps,
        $Count: true
      };
      if (expand) {
        params["$expand"] = expand;
      }
      if(filter){
        params["$filter"] = filter;
      }
      if(sort){
        params["$orderby"] = `${sort.field} ${sort.sortOrder || "asc"}`
      }
      params["$Top"] = pageSize;
      params["$Skip"] = currentPage == 1 ? 0 : (currentPage - 1) * pageSize;
      return this.get("/approvalGroup", params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  selectSubmitter(currentPage: number = 1,pageSize: number = 20, filter: string | null, sort: string, expand?: string): Promise<any> {
    try {
      let params: any = {
        $Count: true
      };
      if (expand) {
        params["$expand"] = expand;
      }
      if(filter){
        params["$filter"] = filter;
      }
      if(sort){
        params["$orderby"] = `${sort}`
      }
      params["$Top"] = pageSize;
      params["$Skip"] = currentPage == 1 ? 0 : (currentPage - 1) * pageSize;
      return this.get("/User", params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  deleteApprovalGroup(params: any): Promise<any> {
    try {
      return this.delete(`/approvalGroup/${params.ApprovalGroupId}`);

    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  userList(selectProps: string, filter: string | null, limit: number | null): Promise<any> {
    try {
      let params: any = {
        $select: selectProps,
      };
      if (filter) {
        params["$filter"] = filter;
        
      }
      if (limit) {
        params["$top"] = limit;
      } else {
        params["$top"] = 100;
      }
      return this.get("/user", params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }
}
