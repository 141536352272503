import { GetterTree } from 'vuex'
import { RootState } from 'store'
import { DashboardState } from './types'

export const getters: GetterTree<DashboardState, RootState> = {
  dropdownSite:(state: DashboardState) => state.dropdownSite,
  action:(state: DashboardState) => state.action,
  timeSheets:(state: DashboardState) => state.timeSheets,
  timeSheetEvents:(state: DashboardState) => state.timeSheetEvents,
  countNotiUnRead:(state: DashboardState) => state.countNotiUnRead,
  countTimeSheetStatus:(state: DashboardState) => state.countTimeSheetStatus,
  filterTimeSheet:(state: DashboardState) => state.filterTimeSheet,
  loading: (state: DashboardState) => state.loading,
  isEditAddTask: (state: DashboardState) => state.isEditAddTask,
  totalHour: (state: DashboardState) => state.totalHour,
  timeSheetsLine: (state: DashboardState) => state.timeSheetsLine,
}
