import { GetterTree } from 'vuex'
import { RootState } from 'store'
import { TimesheetApproveState } from './types'

export const getters: GetterTree<TimesheetApproveState, RootState> = {
  listTimeSheets:(state: TimesheetApproveState) => state.timeSheetsApprove,
  filters:(state: TimesheetApproveState) => state.filters,
  totalRowFetchTimeSheet:(state: TimesheetApproveState) => state.totalRowFetchTimeSheet,
  loading: (state: TimesheetApproveState) => state.loading,
  totalHour: (state: TimesheetApproveState) => state.totalHour,
  sortParams: (state: TimesheetApproveState) => state.sortParams,
}
