<template>
  <div class="icon-calendar"
  >
    <icon-svg
      width="14"
      height="14"
      name="calendar"
      /> 
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import IconSvg from '@/layouts/IconBase.vue';

export default defineComponent({
  name: "IconCalendar",
  props: {
    label: String,
    required: Boolean,
  },
  components: {
    IconSvg,
  },
  setup(props) {
    
  }
});
</script>

<style lang="scss" >
 
</style>