import { GetterTree } from 'vuex'
import { RootState } from 'store'
import { AuthState } from './types'
import { AUTH_TOKEN_KEY } from 'core/constants'

export const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated: (state: AuthState) => {
    var token =localStorage.getItem(AUTH_TOKEN_KEY) || sessionStorage.getItem(AUTH_TOKEN_KEY);
    if(!token){
      return false;
    }else{
      const base64Url = token.split('.')[1];
      const base64 =  base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      const jwtPayload = JSON.parse(jsonPayload);
      if (jwtPayload.exp < Date.now()/1000) {
        return false;
      }else{
        return true;
      }
    }
  },
  currentUser:(state: AuthState) => state.currentUser,
  allFieldIsValid:(state: AuthState) => state.allFieldIsValid,
}
