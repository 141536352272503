<template>
  <div class="content popup-employee">
    <div class="custom-input pr-[40px] mb-[20px]">
      <div class="flex gap-4 content-ls ...">
        <!-- Condition Search [START] -->
        <div class="flex-1 ...">
          <div class="grid grid-flow-col gap-4 auto-cols-max">
            <el-input
              ref="refEmail"
              v-model="userCondition.Search"
              placeholder="Search Employee ID and Name"
              class="filter-input"
              style="width: 300px"
              @change="handleSearch('filter')"
            ></el-input>
            <el-input
              ref="refEmail"
              v-model="userCondition.Department"
              placeholder="Department"
              class="filter-input"
              @change="handleSearch('filter')"
            ></el-input>
            <el-input
              ref="refEmail"
              v-model="userCondition.Location"
              placeholder="Location"
              class="filter-input"
              @change="handleSearch('filter')"
            ></el-input>

            <el-select
              ref="refuserType"
              v-model="userCondition.PayCycle"
              placeholder="Select Pay Cycle"
              size="large"
              class="w-full"
              filterable
              clearable
              @change="handleSearch('filter')"
            >
              <el-option
                v-for="item in gpPayCycleGroup"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              />
            </el-select>

            <div class="grid grid-flow-col">
              <el-button
                class="btn-clear-filters btn-size-1 border-none mr-2 w-[142px]"
                @click="reset()"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path 
                    d="M11.7256 10.25C12.4043 10.6473 13.1758 10.9033 13.9997 10.9775C13.9999 10.985 14 10.9925 14 11C14 11.4142 13.6642 11.75 13.25 11.75H4.75C4.33579 11.75 4 11.4142 4 11C4 10.5858 4.33579 10.25 4.75 10.25H11.7256ZM9.02242 6C9.06971 6.52473 9.19069 7.0282 9.37494 7.5H2.75C2.33579 7.5 2 7.16421 2 6.75C2 6.33579 2.33579 6 2.75 6H9.02242ZM12 15.25C12 14.8358 11.6642 14.5 11.25 14.5H6.75C6.33579 14.5 6 14.8358 6 15.25C6 15.6642 6.33579 16 6.75 16H11.25C11.6642 16 12 15.6642 12 15.25ZM14.5 10C16.9853 10 19 7.98528 19 5.5C19 3.01472 16.9853 1 14.5 1C12.0147 1 10 3.01472 10 5.5C10 7.98528 12.0147 10 14.5 10ZM16.3536 3.64645C16.5488 3.84171 16.5488 4.15829 16.3536 4.35355L15.2071 5.5L16.3536 6.64645C16.5488 6.84171 16.5488 7.15829 16.3536 7.35355C16.1583 7.54882 15.8417 7.54882 15.6464 7.35355L14.5 6.20711L13.3536 7.35355C13.1583 7.54882 12.8417 7.54882 12.6464 7.35355C12.4512 7.15829 12.4512 6.84171 12.6464 6.64645L13.7929 5.5L12.6464 4.35355C12.4512 4.15829 12.4512 3.84171 12.6464 3.64645C12.8417 3.45118 13.1583 3.45118 13.3536 3.64645L14.5 4.79289L15.6464 3.64645C15.8417 3.45118 16.1583 3.45118 16.3536 3.64645Z" 
                    :fill="currentTheme != 'light' ? '#fff' : '#3A4056'"
                  />
                </svg>
                <span class="ml-2.5">Clear Filters</span>
              </el-button>


            </div>
          </div>
          <!-- Condition Search [END] -->
        </div>
        <div
          class="grid grid-flow-col justify-items-stretch items-end ..."
        ></div>
      </div>
    </div>

    <div v-if="loadingEmployee">
      <div class="loading-indicator">
        <div class="loading-spinner"></div>
      </div>
    </div>
    <div v-else>
      <el-table
        ref="multipleTableRef"
        class="custom-table table-popup-employee"
        :data="employeeList"
        @sort-change="handleSortChange"
        :row-class-name="tableRowClassName"
        @row-click="rowClick" 
      >
        <el-table-column width="55" class-name="text-center">
          <template #default="scope">
            <input
              class="cursor-pointer"
              type="checkbox"
              :checked="isSelected(scope)"
            />
          </template>
        </el-table-column>

        <el-table-column
          label="Employee ID"
          class-name="text-left"
          width="160"
          sortable
        >
          <template #default="scope">
            {{ scope.row.EmployeeId }}
          </template>
        </el-table-column>

        <el-table-column
          prop="FirstName"
          label="First Name"
          class-name="text-left"
          width="100px"
        />
        <el-table-column
          prop="LastName"
          label="Last Name"
          class-name="text-left"
          width="190px"
        />
        <el-table-column
          prop="FullName"
          label="Name"
          class-name="text-left"
          min-width="140px"
        />
        <el-table-column
          prop="StaffNumber"
          label="Staff Number"
          class-name="text-left"
          min-width="140px"
        />

        <el-table-column
          prop="StaffType"
          label="Staff Type"
          class-name="text-left"
          width="190px"
        />

        <el-table-column
          prop="Department"
          label="Department"
          class-name="text-left"
          width="140px"
        />
        <el-table-column
          prop="Location"
          label="Location"
          class-name="text-left"
          width="190px"
        />

        <el-table-column
          prop="PayCycle"
          label="Pay Cycle"
          class-name="text-left"
        />
      </el-table>
    </div>
    <div
      class="pagination-custom mr-5 ml-[40px] mt-2 mb-2 flex flex-row-reverse"
      v-if="!loadingEmployee"
    >
      <el-pagination
        v-model:currentPage="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[20, 40, 100]"
        :small="small"
        :disabled="disabled"
        :background="background"
        layout="total, sizes, prev, pager, next"
        :total="employeeCount"
        popper-class="popper-class"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <div class="flex justify-end p-[0px] pt-2 pb-6" v-if="!loadingEmployee">
      <el-button @click="handleClose()" class="btn-cancel">Cancel</el-button>
      <el-button
        type="primary"
        class="btn-confirm ml-[20px]"
        @click="handleSubmit()"
      >
        <span>Ok</span>
      </el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { TableColumnCtx } from "element-plus/es/components/table/src/table-column/defaults";
import IconSvg from "@/layouts/IconBase.vue";

import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";

interface SpanMethodProps {
  row: any;
  column: TableColumnCtx<any>;
  rowIndex: number;
  columnIndex: number;
}
export default defineComponent({
  name: "employee",
  props: {
    popupGPEmployees: Boolean,
  },
  components: {
    IconSvg,
  },

  setup(props, ctx) {
    watch(props, () => {
      if (!props.popupGPEmployees) {
        reset();
      } else {
        handleSearch()
      }
    });
    const store = useStore();
    const showDialog = ref<boolean>(false);
    const currentPage = ref(1);
    const pageSize = ref(20);
    const small = ref(false);
    const background = ref(true);
    const disabled = ref(false);
    const employeeList:ComputedRef<any[]> = computed(() => store.getters['user/employeeList']);
    const employeeCount = computed(() => store.getters['user/employeeCount']);
    const loadingEmployee = computed(() =>store.getters['user/loadingEmployee']);
    const selectAll = ref(false);
    const multipleSelection = ref<any[]>([]);
    const currentTheme = computed(() => store.state.currentTheme);

    onBeforeMount(() => {
      handleSearch()
    });

    const handleSortChange = async ({ column, prop, order }: any) => {};

    const handleClose = () => {
      ctx.emit("closeGPGPEmployees");
      multipleSelection.value = [];
    };

    const isSelected = (scope: any) => {
      return multipleSelection.value.some(
        (item) => item.EmployeeId === scope?.row?.EmployeeId
      );
    };

    const toggleItem = (scope: any) => {
      if (isSelected(scope)) {
        multipleSelection.value = [];
      } else {
        multipleSelection.value = [scope.row];
      }
    };

    const gpPayCycleGroup: any[] = [
      {
        id: 0,
        value: "None",
      },
      {
        id: 1,
        value: "Rental",
      },
      {
        id: 2,
        value: "Workshops",
      },
    ];

    const tableRowClassName = ({
      row,
      rowIndex,
    }: {
      row: any;
      rowIndex: number;
    }) => {
      if (multipleSelection?.value?.find((e) => e.EmployeeId == row.EmployeeId)) {
        return "seleted-row";
      }
      return "";
    };

    const displayPayCycle = (status: any) => {
      switch (status) {
        case 0:
          return "None";
        case 1:
          return "Rental";
        case 2:
          return "Workshops";
        default:
          return "None";
      }
    };

    const handleSearch = (type?: string) => {
      let arrFilter = [];

      if (userCondition.value.Search) {
        const query = `(contains(tolower(FullName), '${userCondition.value.Search}') or (contains(tolower(EmployeeId), '${userCondition.value.Search}')))`;
        arrFilter.push(query);
      }
      
      if (userCondition.value.Department) {
        const query = `contains(tolower(Department), '${userCondition.value.Department}')`;
        arrFilter.push(query);
      }
      
      if (userCondition.value.Location) {
        const query = `contains(tolower(Location), '${userCondition.value.Location}')`;
        arrFilter.push(query);
      }

      if (userCondition.value.PayCycle || userCondition.value.PayCycle == 0) {
        const _payCycle = displayPayCycle(userCondition.value.PayCycle)
        const query = `contains(tolower(PayCycle), '${_payCycle}')`;
        arrFilter.push(query);
      }

      if (type == 'filter') {
        currentPage.value = 1
      }

      let param = {
        count: true,
        currentPage: currentPage.value,
        pageSize: pageSize.value,
        conditionSearch: arrFilter,
        orderBy: null
      };

      store.commit('user/SET_LOADING_EMPLOYEE', true);
      store.dispatch("user/getListGPEmployee", param).then((response) => {
        store.commit('user/SET_EMPLOYEE_LIST', response.value);
        store.commit('user/SET_EMPLOYEE_COUNT', response["@odata.count"]);
        store.commit('user/SET_LOADING_EMPLOYEE', false);
      });

    };

    const userCondition = ref({
      Search: "",
      Department: "",
      Location: "",
      PayCycle: null,
    });

    const reset = () => {
      clearSelect();
      if (userCondition.value.Search || userCondition.value.Department || userCondition.value.Location || userCondition.value.PayCycle || userCondition.value.PayCycle == 0) {
        userCondition.value = {
          Search: "",
          Department: "",
          Location: "",
          PayCycle: null,
        };
        handleSearch()
      }
      multipleSelection.value = [];
      currentPage.value = 1;
    };

    const clearSelect = () => {
      selectAll.value = false;
    };

    const handleSubmit = () => {
      ctx.emit("selectItemGPEmployees", multipleSelection.value[0]);
      multipleSelection.value = [];
    };

    const handleSizeChange = (val: number) => {
      clearSelect();
      pageSize.value = val
      handleSearch('filter')
    };
    const handleCurrentChange = (val: number) => {
      clearSelect();
      currentPage.value = val
      handleSearch('paginate')
    };

    const rowClick =  (row: any) => {
      isSelected(row)
      if (isSelected(row)) {
        multipleSelection.value = [];
      } else {
        multipleSelection.value = [row];
      }
    }
    

    return {
      showDialog,
      handleClose,
      handleSortChange,
      userCondition,
      reset,
      isSelected,
      toggleItem,
      handleSubmit,
      loadingEmployee,
      gpPayCycleGroup,
      tableRowClassName,
      handleSearch,
      currentPage,
      pageSize,
      small,
      background,
      disabled,
      employeeCount,
      handleSizeChange,
      handleCurrentChange,
      employeeList,
      currentTheme,
      rowClick
    };
  },
});
</script>

<style lang="scss">
$--el-light-table-row-hover: #e5e5e5;
$--el-light-table-row-stripe: #efefef;
$--el-dark-table-row-hover: #484b5b;
$--el-dark-table-row-stripe: #303342;
$--el-dark-table-row-hover: #303342;
$--el-dark-table-row-active-hover: #2f3f62;
$--el-dark-table-row-active: #24314d;
$--el-light-table-row-hover: #efefef;
$--el-light-table-row-active-hover: rgba(36, 107, 253, 0.08);

.el-table.custom-table .el-table__header tr th {
  background: #231f20;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.el-table.custom-table .el-table__header tr th:first-child {
  border-top-left-radius: 12px;
  padding-left: 24px;
}

.el-table.custom-table .el-table__header tr th:last-child {
  border-top-right-radius: 12px;
}

.el-table.custom-table tr td:first-child {
  padding-left: 24px;
}

.el-dialog-custom .el-dialog.popup-view-log .el-dialog__header {
  margin-bottom: 30px !important;
}

.dark {
  .popup-gp-employee {
    .content {
      .el-table.custom-table .el-table__header {
        background-color: #252a34;
      }
      .el-table__header tr th {
        background: #3a4056;
        color: #ffffff;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }
    }
    .el-table .el-table__cell {
      padding: 12px 0 10px 0;
      font-weight: 600;
      color: #fff;
    }
    .table-popup-employee {
      .el-table__body-wrapper {
        min-height: 300px;
        background-color: #252a34;
      }
    }
  }
}

.table-popup-employee {
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  .el-table__body-wrapper {
    min-height: 300px;
  }
}

.dark {
  .table-popup-employee {
    border: 1px solid #3A4056;
    border-radius: 12px;
  }
}

.popup-gp-employee {
  .el-table__header-wrapper{
    overflow: hidden;
    border-top-right-radius: 0px !important;
  }
  .el-dialog__header {
    margin: 40px 0 20px 40px !important;
  }
  .el-dialog__body {
    .content {
      padding-left: 40px;
      padding-right: 40px;

      .loading-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        font-size: 18px;
        color: #555;
      }

      .loading-spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-left: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .el-table .el-table__cell {
    padding: 12px 0 10px 0;
    font-weight: 600;
    color: #231f20;
  }

  .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    border-color: none;
    cursor: pointer;
  }

  .el-table--enable-row-hover .el-table__body tr:hover td.el-table__cell,
  .el-table .el-table__body tr.el-table__row:hover td.el-table__cell {
    background: $--el-light-table-row-hover !important;
  }
  .el-table--enable-row-hover .el-table__body tr:hover td.el-table__cell,
  .el-table
    .el-table__body
    tr.el-table__row.seleted-row:hover
    td.el-table__cell {
    background: $--el-light-table-row-active-hover !important;
  }

  .el-table .seleted-row,
  .el-table--striped
    .el-table__body
    tr.el-table__row--striped.seleted-row
    td.el-table__cell {
    background: $--el-light-table-row-active-hover !important;
  }
  .seleted-row {
    position: relative;
  }
  .seleted-row::after {
    position: absolute;
    content: "";
    display: block;
    border-width: 2px;
    border-style: solid;
    border-color: #246bfd;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
  .cell {
    padding: 0 !important;
    margin-right: 8px;
  }
  //checkbox
  input[type="checkbox"]:focus {
    outline: none;
    box-shadow: none;
  }
  [type="checkbox"] {
    background: transparent;
    border-width: 1px;
    border-radius: 2px;
  }
  [type="checkbox"]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e") !important;
  }
  [type="checkbox"]:hover {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e") !important;
    background-color: #9398a0;
  }
  [type="checkbox"]:checked,
  [type="radio"]:checked {
    border-color: transparent;
    background-color: currentColor !important;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.dark {
  .popup-employee {
    .btn-clear-filters {
      padding: 10px 16px !important;
      background: #262A34;
      color: #FFFFFF;
      border: 1px solid #3A4056 !important;
      &:hover {
        opacity: 0.8;
        background: #262A34 !important;
        box-shadow: 0 0 0 1px #262A34 inset;
      }
      &:focus {
        background: #262A34 !important;
      }
    }
    th .cell {
      color: #fff !important;
    }
  }

}
.popup-employee { 
  .btn-clear-filters {
    padding: 10px 16px !important;
    background: #fff;
    color: #262A34;
    border: 1px solid rgb(229, 231, 235) !important;
    &:hover {
      background-color: #fff !important;
      box-shadow: 0 0 0 1px rgba(35, 31, 32, 0.3) inset;
    }
    &:focus {
      background-color: #fff !important;
    }
    animation: all .5s ease-in;
  }
}

.el-dialog.popup-gp-employee {
  margin-bottom: 0;
  .el-dialog__body {
    height: calc(100vh - 178px);
    .content.popup-employee {
      height: inherit;
      .custom-table.table-popup-employee {
        height: calc(100vh - 364px);
      }
    }
  }
}


</style>
