import Service from 'core/service'
import { FilterTimeSheetTracking } from '../store/types';

const PAGE_SIZE = 25;
export class TimeSheetTrackingService extends Service {
    fetchTimeSheetTracking(filter: FilterTimeSheetTracking, currentPage: number = 1, pageSize: number = PAGE_SIZE,) {
        const dataFilter = {...filter}
        dataFilter._Top = pageSize;
        dataFilter._Skip = currentPage == 1 ? 0 : (currentPage - 1) * pageSize;
        if(dataFilter.Status == -1){
            dataFilter.Status = undefined
        }
        return this.get("/TimesheetHistory/GetExpands", dataFilter);
    }

}