<template>
  <div class="header-container">
    <div class="flex ... align-middle">
      <div class="grow align-middle ...">
        <div class="grid logo-item">
          <div class="place-self-stretch ....">
            <icon-svg
              :width="windowWidth < 1440?'120px':'140px'"
              :height="windowWidth < 1440 ? '36px' : '42px'"
              name="logo"
            />
          </div>
        </div>
      </div>
      <div class="grow pr-5 text-right align-middle">
        <div
          class="grow align-right">
          <el-button
            v-if="(currentUser.userType == USER_TYPE_SYSTEM_ADMINISTRATOR || currentUser.userType == USER_TYPE_USER_ADMINISTRATOR)"
            type="danger"
            class="btn-bg-color-red btn-default-2 border-none  w-[166px] custom-button-header" @click="handleCreateUser">
            <icon-svg
              width="20"
              height="20"
              name="create-account" />
            <span class="ml-2">Create Account</span>
          </el-button>
          
          <el-button
            v-if="(currentUser.userType == USER_TYPE_SYSTEM_ADMINISTRATOR || currentUser.userType == USER_TYPE_USER_ADMINISTRATOR)"
            type="danger"
            class="btn-bg-color-red btn-default-2 border-none w-[215px] custom-button-header" @click="handleCreateGroup">
            <icon-svg
              width="20"
              height="20"
              name="create-group" />
            <span class="ml-2">Create Approval Group</span>
          </el-button>
          
          <el-button
            v-if="(currentUser.userType == USER_TYPE_APPROVER && currentUser.allowTimesheetCreation)"
            type="danger"
            class="btn-bg-color-red btn-default-2 border-none w[-190px] custom-button-header" @click="handleAddNewTask">
            <icon-svg 
              width="20"
              height="20"
              name="plus-circle" />
            <span class="ml-2">Create Timesheet</span>
          </el-button>
          
          <el-button
            v-if="(currentUser.userType != USER_TYPE_USER_ADMINISTRATOR && currentUser.userType != USER_TYPE_APPROVER)"
            type="danger"
            class="btn-bg-color-red btn-default-2 border-none w-[190px] custom-button-header" @click="handleAddNewTask">
            <icon-svg 
              width="20"
              height="20"
              name="plus-circle" />
            <span class="ml-2 ">Create Timesheet</span>
          </el-button>
        </div>
        
      </div>
      <div class="grow-0 ... user-info">
        <div class="flex flex-row-reverse align-middle ...">
          <div class="flex ... align-middle gap-5">
            <div class="w-[180px] overflow-hidden whitespace-nowrap overflow-ellipsis">
              <span class="user-greet text-sm font-medium">{{ `Welcome, ${currentUser.firstName ? currentUser.firstName: ''} ${currentUser.lastName ? currentUser.lastName: ''}` }}</span>
              <div class="grow col-span-2 user-type text-xs font-bold">
                {{ currentUser.userType }}
              </div>
            </div>
            <div class="grow">
              <el-button
                  key="danger"
                  type="danger"
                  text
                  bg
                  class="btn-userinfo btn-1 bg-color-1"
                  @click="handleLogout"
              >
                <icon-svg
                  width="18"
                  height="18"
                  name="logout-header"
                  :iconColor="iconColor"/>
                <span class="ml-2.5"> Log Out</span>
              </el-button>
            </div>
          </div>
          <div>
            <!-- Temporarily hidden avatar popup -->

            <!-- <el-tooltip :content='`
              <div class="tooltip-info-user">
                <img class="w-[242px]" src="${currentUser.avatarUrl ? `${imageEndpoint}/${currentUser.avatarUrl}?t=${ Date.now() }` : avatarDefault}" />
                </div>`' raw-content :effect="'light'"> -->
              <div class="flex flex-cols text-left align-middle">
                <img class="avatar w-[40px] h-[40px] object-contain" alt="Vue logo" 
                :src="currentUser.avatarUrl ? `${imageEndpoint}/${currentUser.avatarUrl}?t=${ Date.now() }` : avatarDefault" />
              </div>
            <!-- </el-tooltip> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, computed, watch } from "vue";
import IconSvg from '@/layouts/IconBase.vue'
import { useRouter, useRoute } from 'vue-router'
import { 
  Search, 
  Close,
  SuccessFilled,
} from '@element-plus/icons-vue'
import { useStore } from "vuex";
import { USER_TYPE_APPROVER, USER_TYPE_SYSTEM_ADMINISTRATOR, AUTH_TOKEN_KEY, USER_TYPE_USER_ADMINISTRATOR } from 'core/constants';
import { msalInstance } from "../../authConfig";
import { imageEndpoint } from '@/config/enviroments';
import avatarDefault from '@/assets/avatar.png';

export default defineComponent({
  name: "BaseHeader",
  components: {
    IconSvg,
  },
  setup() {
    const currentUser = computed(() => store.getters['auth/currentUser'])
    const windowWidth = window.innerWidth
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const handleCreateUser = () => {
      store.commit('SET_OPEN_POPUP_USER_FORM', true)
    }
    const handleCreateGroup = () => {
      store.commit('SET_EDIT_GROUP_FORM', null)
      store.commit('SET_OPEN_POPUP_GROUP_FORM', true)
    }
    const handleAddNewTask = () => {
      store.commit("SET_OPEN_POPUP_ADD_TASK", true);
    }
    const iconColor = computed(() =>
      store.state.currentTheme === 'auto' || store.state.currentTheme === 'dark' ? "#FFFFFF" :  "#262A34"
    );

    const handleLogout = () => {
      localStorage.setItem(AUTH_TOKEN_KEY, '');
      sessionStorage.setItem(AUTH_TOKEN_KEY, '');
      router.push('/login');
    }
    return {
      // userInfo,
      currentUser,
      iconColor,
      Search, 
      Close,
      SuccessFilled,
      handleCreateUser,
      handleCreateGroup,
      handleAddNewTask,
      handleLogout,
      USER_TYPE_APPROVER,
      USER_TYPE_SYSTEM_ADMINISTRATOR,
      USER_TYPE_USER_ADMINISTRATOR,
      imageEndpoint,
      avatarDefault,
      windowWidth
    }
  }
});

</script>

<style lang="scss" scoped>
  .btn-userinfo.el-button{
    padding: 10px 20px;
    height: 40px;
    width: 120px;
    border: 1px solid rgba(35, 31, 32, 0.1);
    border-radius: 6px !important;
  }
  .avatar {
    border-radius: 50%;
  }
  .user-type {
    border-radius: 4px;
    width: fit-content;
    font-weight: 700;
    color: #231F20;
  }
  .user-greet {
    color: #4F4F4F;
  }
  .user-info .btn-userinfo{
    background-color: transparent !important;
    border-radius: 6px;
    span {
      color: #262A34;
    }
  }
  .el-button:hover {
    opacity: 0.8 !important;
    color: white !important;
  }
  .dark {
    .btn-userinfo {
      border: 1px solid #3A4056;
    }
    .el-button:focus:not(:disabled) {
      outline: none;
      opacity: 1 !important;
      background: #ED1C2D;
      color: white;
    }
    .el-button:hover {
      background: #ED1C2D !important;
      opacity: 0.8 !important;
    }
    .user-type {
      border-radius: 4px;
      width: fit-content;
      font-weight: 700;
      color: #FFFFFF;
    }
    .user-greet {
      color: #FFFFFF;
    }
    .user-info .btn-userinfo.bg-color-1{
      background-color: transparent !important;
      border: 1px solid #3A4056;
      border-radius: 6px;
      span {
        color: #FFFFFF;

      }
     
    } 
  }
  .el-button:focus:not(:disabled) {
    outline: none;
    opacity: 1 !important;
    background: #ED1C2D;
    color: white;
  }
  @media screen and (max-width:1439px) {
    .user-greet{
      font-size: 12px;
      line-height: 16px;
    }
    .user-type{
      font-size: 12px;
      line-height: 16px;
    }
    .btn-userinfo.el-button{
    height: 36px;
    width: 120px;
    border: 1px solid rgba(35, 31, 32, 0.1);
    border-radius: 6px;
  }
  .custom-button-header{
    padding:8px !important
  }
  }
  @media screen and (min-width:1440px) and (max-width:1536px) {
    .user-greet{
      font-size: 14px;
      line-height: 20px;
    }
  }

.btn-default-2 {
  padding: 10px 16px !important;
}

</style>

