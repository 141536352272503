import { createStore } from 'vuex'
import { auth } from '../modules/auth/store/index'
import { dashboard } from '../modules/dashboard/store/index'
import { profile } from '../modules/profile/store/index'
import { manual } from '../modules/manual/store/index'
import { approvalGroup } from '../modules/admin/groups/store/index'
import { user } from '../modules/admin/users/store/index'
import { timeSheetsApprove } from '../modules/super/dashboard/store/index'
import { timeSheetTracking } from 'modules/super/timesheetTrackings/store'
import { setting } from '../modules/settings/store/index'
import { AUTO_THEME, DARK_THEME, LIGHT_THEME } from 'core/constants'

export type RootState = {
  version: string
  initialized: boolean
  loading: boolean
  statusCode: number
  isOpen: boolean
  currentTheme: string
  isOpenPopupUserForm: boolean
  isOpenPopupGroupForm: boolean
  editApproveGroup?: ApprovalGroupModel
  isOpenPopupAddTask: boolean
  isAfterEditAprroveGroup: boolean
  filterSelect: any
  isCollapseSidebar:boolean
}

const store = createStore<RootState>({
  state: {
    version: '1.0.0',
    initialized: false,
    loading: false,
    statusCode: 404,
    isOpen: false,
    currentTheme: 'light',
    isOpenPopupUserForm: false,
    isOpenPopupGroupForm: false,
    isOpenPopupAddTask: false,
    isAfterEditAprroveGroup: false,
    filterSelect: {},
    isCollapseSidebar:false
  },
  mutations: {
    INITIALIZED: (state: RootState, status: boolean) => {
      state.initialized = status
    },
    SET_LOADING: (state: RootState, status: boolean) => {
      state.loading = status
    },
    SET_STATUS_CODE: (state: RootState, statusCode: number) => {
      state.statusCode = statusCode
    },
    SET_OPEN_POPUP_TIMEOUT: (state: RootState, open: boolean) => {
      state.isOpen = open
    },
    SET_THEME: (state: RootState, theme: string) => {
      console.log(theme)
      state.currentTheme = theme
    },
    SET_OPEN_POPUP_USER_FORM:(state: RootState, isOpen: boolean) => {
      state.isOpenPopupUserForm = isOpen
    },
    SET_OPEN_POPUP_GROUP_FORM:(state: RootState, isOpen: boolean) => {
      state.isOpenPopupGroupForm = isOpen
    },
    SET_EDIT_GROUP_FORM:(state: RootState, itemGroup?: ApprovalGroupModel) => {
      state.editApproveGroup = itemGroup
    },
    SET_OPEN_POPUP_ADD_TASK:(state: RootState, isOpen: boolean) => {
      state.isOpenPopupAddTask = isOpen
    },
    SET_AFTER_EDIT_APPROVE_GROUP:(state: RootState, isOpen: boolean) => {
      state.isAfterEditAprroveGroup = isOpen
    },
    SET_FILTER_SELECT_APPROVE_GROUP:(state: RootState, filter: any) => {
      state.filterSelect = filter
    },
    TOGGLE_SIDEBAR:(state:RootState)=>{
      state.isCollapseSidebar = !state.isCollapseSidebar
    }
  },
  actions: {
    initialized(ctx, status?: boolean) {
      ctx.commit('INITIALIZED', status)
      let theme = localStorage.getItem('vueuse-color-scheme');
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      const prefersLight = window.matchMedia('(prefers-color-scheme: light)');
   
      if (theme === AUTO_THEME) {
        if (prefersDark.matches) { 
          ctx.commit('SET_THEME', DARK_THEME)
        } 
        if (prefersLight.matches) {
          ctx.commit('SET_THEME', LIGHT_THEME)
        }  
      } else {
        if (theme === LIGHT_THEME) {
        ctx.commit('SET_THEME', LIGHT_THEME)
        }
        if (theme === DARK_THEME) {
          ctx.commit('SET_THEME', DARK_THEME)
        }
      }
    },
  },
  getters: {
    currentTheme: (state: RootState) => state.currentTheme,
  },
  modules: {
    auth,
    dashboard,
    profile,
    manual,
    approvalGroup,
    user,
    timeSheetsApprove,
    timeSheetTracking,
    setting
  },
})

export default store