import { MutationTree } from "vuex";
import { AuthState, CurrentUserModel, SET_USER, SET_IS_VALID } from "./types";

export const mutations: MutationTree<AuthState> = {
  [SET_USER](state: AuthState, user: CurrentUserModel) {
    state.currentUser = user;
  },
  [SET_IS_VALID](state: AuthState, data: boolean ) {
    state.allFieldIsValid = data;
  },
};
