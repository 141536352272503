import store, { RootState } from "store";
import { ActionTree } from "vuex";
import { SettingsService } from "../service";
import { SettingState } from "./types";

const service = new SettingsService()

export const actions: ActionTree<SettingState, RootState> = {

  async getSettings({ commit }, params: any) {
    const data = await service.getSettings()
    commit('SET_SETTINGS', data);
    return data
  },

  async deletePayLocation({ commit }, params: any) {
    const data = await service.deletePayLocation(params)
    return data
  },

  async patchSettings({ commit }, params: any) {
    const data = await service.patchSettings(params)
    commit('SET_SETTINGS', data);
  },
  
  async resetSetting({ commit }) {
    await service.resetSetting()
  },

  async dropdownPayLocation({ commit }, params: any) {
    let filter = ``;
    if (params.search) {
      filter = `contains(tolower(Name),'${params.search.toLowerCase()}')`
    }
    params.filter = filter;
    const selectProps = "PayLocationId, Name, Region"; 
    const data = await service.dropdownPayLocation(selectProps, params)
    commit('SET_PAYLOCATION_COUNT', data["@odata.count"])
    return data.value
  },

  async patchPayLocation({ commit }, params: any) {
    await service.patchPayLocation(params)
  },
  
  async updatePayLocation({ commit }, params: any) {
    await service.updatePayLocation(params.data, params.id)
  },

  async getPayLocationAndRegion({ commit }, params: any) {
    let filter = ``;
    if (params.search) {
      if (params.search.Region) {
        filter = `contains(tolower(Name),'${params.search.Name.toLowerCase()}') and Region eq '${params.search.Region}'`
      } else {
        filter = `contains(tolower(Name),'${params.search.Name.toLowerCase()}')`
      }
    }
    params.filter = filter;
    const selectProps = "PayLocationId, Name, Region"; 
    const data = await service.dropdownPayLocation(selectProps, params)
    return data.value
  },
  
  async getListRegion({ commit }, params: any) {
    const selectProps = "PayLocationId, Name, Region"; 
    const data = await service.dropdownPayLocation(selectProps, params)
    return data.value
  },


};