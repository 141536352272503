﻿import env from "core/env";

export const imageEndpoint :string = env('VITE_VUE_APP_IMAGE_ENDPOINT') ?? ''

export const firebaseConfig = {
  apiKey: "AIzaSyBtcVTfhWFjMc6_OEvWPky6RI-li-FDZME",
  authDomain: "emeco-timesheets.firebaseapp.com",
  projectId: "emeco-timesheets",
  storageBucket: "emeco-timesheets.appspot.com",
  messagingSenderId: "860335375205",
  appId: "1:860335375205:web:74f1ff0c17aea31b3a978c",
  measurementId: "G-X5NYCTWS58"
}