import { MutationTree } from "vuex";
import { UserState } from "./types";

export const mutations: MutationTree<UserState> = {
    SET_USER_LIST: (state: UserState, userList: UserModel[]) => {
        state.userList = userList;
    },
    SET_USER_LOGS: (state: UserState, userLogs: UserModel[]) => {
        state.userLogs = userLogs;
    },
    SET_USER_COUNT: (state: UserState, userCount: number) => {
        state.userCount = userCount;
    },
    SET_USER_LOGS_COUNT: (state: UserState, userLogscount: number) => {
        state.userLogsCount = userLogscount;
    },
    SET_LOADING_USER_LOGS: (state: UserState, status: boolean) => {
        state.loadingUserLogs = status
    },
    SET_USER_PRAM: (state: UserState, userParam: any) => {
        state.userParam = userParam;
    },
    SET_DEFAULT_START_TIME: (state: UserState, defaultStartTime: any) => {
        state.defaultStartTime = defaultStartTime;
    },
    SET_LOADING_USER: (state: UserState, status: boolean) => {
        state.loading = status
    },
    SET_EMPLOYEE_LIST: (state: UserState, userList: UserModel[]) => {
        state.employeeList = userList;
    },
    SET_LOADING_EMPLOYEE: (state: UserState, status: boolean) => {
        state.loadingEmployee = status
    },
    SET_EMPLOYEE_COUNT: (state: UserState, userCount: number) => {
        state.employeeCount = userCount;
    },
    SET_LOADING_SEND_MAIL: (state: UserState, status: boolean) => {
        state.loadingSendMail = status;
    },
    SET_LOADING_SEND_MAIL_SUCCESS: (state: UserState, status: string) => {
        state.sendMailResult = status;
    },
    SET_LOADING_SEND_MAIL_FAIL: (state: UserState, status: string) => {
        state.sendMailResult = status;
    },
};
