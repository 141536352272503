<template>
  <div class="detail-task">
    <el-row>
      <el-col :span="12">
        <div class="flex flex-nowrap items-center custom-single-picker">
          <span class="mr-[12px] font-semibold">Start Date</span>
          <el-date-picker v-model="timeSheetHeader.StartDate" type="date" placeholder="Start Date"
            class="h-[40px] w-[180px]" popper-class="popper-single-class" format="DD MMM YYYY" @focus="datePickerfocus"
            ref="datePickerStartDate" :prefix-icon="IconCalendar" :disabled="!allowUpdate" @change="handleCalendarChange"/>
        </div>
        <div class="flex flex-nowrap items-center mt-[8px]" v-if="!timeSheetHeader.StartDate">
          <div class="ml-[80px]"></div>
          <div class="font-medium" style="color: #ed1c2d">
            Please choose start date
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="flex flex-nowrap items-center justify-start">
          <div>
            <div class="flex-1 h-10" align="left">
              <el-button :disabled="!allowUpdate || !timeSheetHeader.Shift" class="btn-time-shift btn-default-2 btn-shift"
                :class="!timeSheetHeader.Shift && 'btn-time-shift-selected'" @click="onConfirmChangeShift('Day')">
                <icon-svg :class="timeSheetHeader.Shift && 'icon-btn-time-shift'" size="24" name="day-shift" />
                <span class="ml-2.5">Day Shift</span>
              </el-button>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="flex flex-nowrap items-center justify-end">
          <div>
            <div class="flex-1 h-10" align="left">
              <el-button :disabled="!allowUpdate || timeSheetHeader.Shift" class="btn-time-shift btn-default-2 btn-shift"
                :class="timeSheetHeader.Shift && 'btn-time-shift-selected'" @click="onConfirmChangeShift('Night')">
                <icon-svg :class="!timeSheetHeader.Shift && 'icon-btn-time-shift'" size="24" name="night-shift" />
                <span class="ml-2.5">Night Shift</span>
              </el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="w-full mt-[20px] mb-[20px] task-divide"></div>
    <div class="mb-[10px]" v-for="(item, index) in timeSheetHeader.TimesheetLines" v-bind:key="item.TaskUuid">
      <el-row class="mb-5">
        <el-col :span="12">
          <div class="h-[32px] justify-center text-xl font-semibold">
            <span>{{ `Task ${index + 1}` }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="flex justify-end">
            <el-button v-if="(timeSheetHeader.TimesheetLines?.length as number) > 1 && (displayButtonAddTask)"
              class="flex h-[32px] w-[32px] items-center justify-center cursor-pointer task-icon-trash"
              @click="removeTask(item.TaskUuid)" :disabled="!allowUpdate">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.0006 2.66666H11.934C11.615 1.11572 10.2507 0.002 8.6673 0H7.33395C5.75055 0.002 4.38623 1.11572 4.0673 2.66666H2.00064C1.63245 2.66666 1.33398 2.96513 1.33398 3.33331C1.33398 3.7015 1.63245 4 2.00064 4H2.6673V12.6667C2.66952 14.5067 4.16061 15.9978 6.00064 16H10.0006C11.8407 15.9978 13.3318 14.5067 13.334 12.6667V4H14.0006C14.3688 4 14.6673 3.70153 14.6673 3.33334C14.6673 2.96516 14.3688 2.66666 14.0006 2.66666ZM7.33398 11.3333C7.33398 11.7015 7.03552 12 6.66733 12C6.29911 12 6.00064 11.7015 6.00064 11.3333V7.33334C6.00064 6.96516 6.29911 6.66669 6.6673 6.66669C7.03548 6.66669 7.33395 6.96516 7.33395 7.33334V11.3333H7.33398ZM10.0006 11.3333C10.0006 11.7015 9.70217 12 9.33398 12C8.9658 12 8.66733 11.7015 8.66733 11.3333V7.33334C8.66733 6.96516 8.9658 6.66669 9.33398 6.66669C9.70217 6.66669 10.0006 6.96516 10.0006 7.33334V11.3333ZM5.44798 2.66666C5.73155 1.86819 6.48667 1.33434 7.33398 1.33331H8.66733C9.51464 1.33434 10.2698 1.86819 10.5533 2.66666H5.44798Z" />
              </svg>
            </el-button>
            <div class="flex h-[32px] w-[32px] items-center justify-center ml-[20px] cursor-pointer task-icon-expand"
              @click="handelExpendTask(item.TaskUuid)">
              <el-icon color="#FFFFFF">
                <ArrowDown v-if="item.Option?.isExpend === false" />
                <ArrowUp v-if="item.Option?.isExpend === true" />
              </el-icon>
            </div>
          </div>
        </el-col>
      </el-row>
      <div :style="{
        display: item.Option?.isExpend === true ? '' : 'none',
      }">
        <div class="flex items-center justify-center">
          <div class="flex flex-row task-type rounded-group-button">
            <div class="flex items-center justify-center job-type cursor-pointer task-type-left"
            v-if="taskTypeDisplay.rental || (isEdit && (item.Option as any)?.type === 'Rental') || !!item.TimesheetLineId" 
              v-on:click=" taskTypeDisplay.rental ? handleOpenPopupChangeTaskType('Rental', item.TaskUuid)  : handleErrorSwitchTaskType()"
              :class="{
                'task-type-selected': item.Option?.type === 'Rental',
                'task-type-disable': !allowUpdate
              }">
              <span class="font-semibold">Rental</span>
            </div>
            <div class="flex items-center justify-center job-type cursor-pointer task-type-middle"
            v-if="taskTypeDisplay.jobCost || (isEdit && (item.Option as any)?.type === 'JobCost')|| !!item.TimesheetLineId"
              v-on:click="taskTypeDisplay.jobCost ? handleOpenPopupChangeTaskType('JobCost', item.TaskUuid) : handleErrorSwitchTaskType()" 
              :class="{
                'task-type-selected': item.Option?.type === 'JobCost',
                'task-type-disable': !allowUpdate
              }">
              <span class="font-semibold">Job Cost</span>
            </div>
            <div class="flex items-center justify-center job-type cursor-pointer task-type-right"
            v-if="taskTypeDisplay.unBilled || (isEdit && (item.Option as any)?.type === 'UnBilled') || !!item.TimesheetLineId"
              v-on:click="taskTypeDisplay.unBilled ?handleOpenPopupChangeTaskType('UnBilled', item.TaskUuid): handleErrorSwitchTaskType()" 
              :class="{
                'task-type-selected': item.Option?.type === 'UnBilled',
                'task-type-disable': !allowUpdate
              }">
              <span class="font-semibold">UnBilled</span>
            </div>
          </div>
        </div>
        <div>
          <el-row class="mt-[20px]">
            <el-col :span="12">
              <div class="flex flex-nowrap items-center">
                <span class="mr-[12px] font-semibold">Start Time</span>
                <TimePicker :additionValues="{
                    TaskUuid: item.TaskUuid,
                    isStartTime: true,
                    endTime: item.Option?.endTime
                  }
                  " :hours="item.Option?.startTime.hours" :minutes="item.Option?.startTime.minutes"
                  :isAm="item.Option?.startTime.isAm" :isInit="item.Option?.startTime.isInit" :disable="!allowUpdate" @submit="updateTaskTime" @changeValidTime="onChangeValidTime"></TimePicker>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="flex flex-nowrap items-center justify-end">
                <span class="mr-[12px] font-semibold">End Time</span>
                <TimePicker :additionValues="{
                    TaskUuid: item.TaskUuid,
                    isEndTime: true,
                    startTime: item.Option?.startTime
                  }
                  " :hours="item.Option?.endTime.hours" :minutes="item.Option?.endTime.minutes"
                  :isAm="item.Option?.endTime.isAm" :isInit="item.Option?.endTime.isInit" :disable="!allowUpdate" @submit="updateTaskTime" @changeValidTime="onChangeValidTime"></TimePicker>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-if="item.Option?.type === 'Rental'" class="custom-input">
          <RentalTask @submit="handleTaskSubmit" :data="{
            index,
            taskId: item.TaskUuid,
            workOrderTaskId: item.WorkOrderTaskId,
            workOrderId: item.WorkOrderId,
            fleetId: item.EquipmentId,
            siteId: item.SiteId,
            jobDescription: item.Notes,
            additionInfo: item.TimesheetAdditional,
            hours: item.Option.hours,
            mins: item.Option.mins,
            timeSheetStatus: timeSheetHeader.TimesheetStatus,
          }" :isEdit="isEdit" :allowUpdate="allowUpdate" :errors="errors" :taskTypeDisplay="taskTypeDisplay" :totalHour="totalHour"
            @updateTimeSheet="handleSubmitTaskTime"
            @changeTouched="(value: any) => onChangeTouched(value, item.TaskUuid)"></RentalTask>
        </div>
        <div v-if="item.Option?.type === 'JobCost'" class="custom-input">
          <JobCostTask @submit="handleTaskSubmit" :data="{
            index,
            taskId: item.TaskUuid,
            jobCostCodeId: item.JobCostCodeId,
            jobId: item.JobId,
            jobDescription: item.Notes,
            additionInfo: item.TimesheetAdditional,
            hours: item.Option.hours,
            mins: item.Option.mins,
            timeSheetStatus: timeSheetHeader.TimesheetStatus,
          }" :isEdit="isEdit" :allowUpdate="allowUpdate" :errors="errors" :taskTypeDisplay="taskTypeDisplay" :totalHour="totalHour"
            @updateTimeSheet="handleSubmitTaskTime" :payLocation="timeSheetHeader.User?.PayLocation ? timeSheetHeader.User?.PayLocation : null"
            @changeTouched="(value: any) => onChangeTouched(value, item.TaskUuid)"></JobCostTask>
        </div>
        <div v-if="item.Option?.type === 'UnBilled'" class="custom-input">
          <UnBilledTask @submit="handleTaskSubmit" :data="{
            index,
            taskId: item.TaskUuid,
            gLAccountId: item.GlaccountId,
            jobDescription: item.Notes,
            additionInfo: item.TimesheetAdditional,
            hours: item.Option.hours,
            mins: item.Option.mins,
            timeSheetStatus: timeSheetHeader.TimesheetStatus,
          }" :isEdit="isEdit" :allowUpdate="allowUpdate" :errors="errors" :taskTypeDisplay="taskTypeDisplay" :totalHour="totalHour"
            @updateTimeSheet="handleSubmitTaskTime"
            @changeTouched="(value: any) => onChangeTouched(value, item.TaskUuid)"></UnBilledTask>
        </div>
      </div>
    </div>
    <div v-if="displayButtonAddTask">
      <div class="flex-1 h-10 mt-[20px]" align="left">
        <el-button class="btn-bg-color-red btn-default-2 btn-add-task" @click="addTask" :disabled="!allowUpdate">
          <icon-svg size="24" name="plus-circle" />
          <span class="ml-2.5">Add Task</span>
        </el-button>
      </div>
    </div>
    <div class="flex items-center justify-center h-[48px] mt-[20px] task-total-border">
      <span class="text-xl font-medium">Total:</span>
      <span style="color: #246bfd" class="ml-[5px] text-xl font-medium">{{
        `${("0" + totalHours).slice(-2)} hrs ${("0" + totalMins).slice(
          -2
        )} mins`
      }}</span>
    </div>
  </div>
  <div class="el-dialog-popup">
    <PopupConfirm :type="'Switching Task'"
      :message="'You are switching to another task. \nThe existing task data will be deleted.'" :lblBtnSubmit="'Yes'"
      :iconPopup="'warring'" :iconBtnName="'check-mark-circle'" @submit="handleSelectTabTask"
      ref="popupConfirmChangeType"></PopupConfirm>
  </div>
  <div class="el-dialog-popup">
    <PopupConfirm :type="'Switching Shift'"
      :message="'You are switching shift flag;\n this might affect the input date.\n Please confirm.'" :lblBtnSubmit="'Yes'" :lblBtnCancel="'No'"
      :iconPopup="'warring'" :iconBtnName="'check-mark-circle'" @submit="onChangeShift(timeSheetHeader.Shift ? 'Day' : 'Night')"
      ref="popupConfirmChangeShift"></PopupConfirm>
  </div>
  <div class="el-dialog-popup">
    <PopupConfirm :type="'Deleting'" :message="'Your data will be deleted. Are you sure?'" :lblBtnSubmit="'Delete'"
      :iconPopup="'icon-popup-delete'" :iconBtnName="'delete'" @submit="handleRemoveTask" ref="popupConfirmDelete">
    </PopupConfirm>
  </div>
  <div class="el-dialog-popup">
    <PopupConfirm :type="'Error'" :message="'You cannot perform this function'" 
      :iconPopup="'close-no-circle-red'" ref="popupError">
    </PopupConfirm>
  </div>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import TimePicker from "@/components/TimePicker.vue";
import { ArrowDown, ArrowUp } from "@element-plus/icons-vue";
import {
  TASK_TYPES,
} from "core/constants";
import RentalTask from "@/components/RentalTask.vue";
import JobCostTask from "@/components/JobCostTask.vue";
import UnBilledTask from "@/components/UnBilledTask.vue";
import PopupConfirm from "@/components/PopupConfirm.vue";
import IconSvg from "@/layouts/IconBase.vue";
import IconCalendar from "@/components/IconCalendar.vue";
import { v4 as uuidv4 } from "uuid";
import { formatTime, getTaskTypId } from "../utils";
import dayjs from "dayjs";
import { Actions } from "modules/dashboard/store/types";
import { TASK_TYPES_VALUE } from "core/constants";
import { CurrentUserModel } from "modules/auth/store/types";
import useTaskDetail from "../composables/useTaskDetail";


export default defineComponent({
  name: "DetailTask",
  props: {
    isEdit: Boolean,
    isTouched: Boolean,
    dataEdit: Object,
    isEndTimePM: Number,
    totalHour: Number,
    shift: Boolean,
    dialogVisible: Boolean,
  },
  components: {
    TimePicker,
    ArrowDown,
    RentalTask,
    JobCostTask,
    UnBilledTask,
    ArrowUp,
    IconSvg,
    IconCalendar,
    PopupConfirm,
  },
  setup(props, ctx) {
    const store = useStore();
    const bgColorTaskType = computed(() =>
      store.state.currentTheme === "dark" ? "#5E6272" : "rgba(35, 31, 32, 0.1)"
    );
   
    const currentUser = computed<CurrentUserModel>(
      () => store.getters["auth/currentUser"]
    );
    const allFieldIsValid = computed(() => store.getters["auth/allFieldIsValid"])
    const isEdit = ref<Boolean>(props.isEdit);

    watch(props,() => {
      if (!props.dialogVisible) {
        isTouchedSwitch.value = false
      }
    })
    const isTouched = ref<Record<string, Boolean>>({});
    const popupConfirmChangeType = ref<InstanceType<typeof PopupConfirm>>();
    const popupConfirmDelete = ref<InstanceType<typeof PopupConfirm>>();
    const popupError = ref<InstanceType<typeof PopupConfirm>>();
    const popupConfirmChangeShift = ref<InstanceType<typeof PopupConfirm>>();
    const overlapTime = ref<Boolean>(false);
    const messageValidTime = ref('')
    const errors = ref<any>({
      overlap: {
        id: null,
        message: null
      }
    });
    const isTouchedSwitch = ref<boolean>(false);
    const {
      totalHours,
      totalMins,
      timeSheetHeader,
      allowUpdate,
      displayButtonAddTask,
      initTimesheetLine,
      resetTaskData,
      updateTimeSheet,
      loadTimeSheetEdit,
      createTimeSheet,
      funcOverLap,
      funcFindMaxMinTime,
      handleSubmitTaskTime,
      updateTaskTime,
      handleTaskSubmit,
      funcOverLapLevel,
    } = useTaskDetail(ctx);

    const currentTheme = computed(() => store.state.currentTheme);
    const allTaskIsValid = computed(() => {
      const findItemValidFail = timeSheetHeader.value.TimesheetLines?.find(
        (x) => x.Option?.isValid == false
      );
      return findItemValidFail != undefined ? false : true;
    });
    const action = computed(() => store.getters["dashboard/action"]);
    
    const taskTypeDisplay = computed(() => {
      const userTaskType = currentUser.value.taskType;
      let taskType = userTaskType
      // if(isEdit.value){
      //   taskType = currentTaskVisibleType.value
      // } else{
      //   taskType = userTaskType
      // }

      const showConfig = {
        rental: false,
        jobCost: false,
        unBilled: false
      }
      if (taskType === TASK_TYPES_VALUE.All) {
        showConfig.rental = true;
        showConfig.jobCost = true;
        showConfig.unBilled = true;
      } else if (taskType === TASK_TYPES_VALUE.Rental) {
        showConfig.rental = true;
      } else if (taskType === TASK_TYPES_VALUE.JobCost) {
        showConfig.jobCost = true;
        showConfig.unBilled = true;
      }
      return showConfig
    })

    const defaultTaskHeaderType = computed(() => {
      if (taskTypeDisplay.value.rental) {
        return TASK_TYPES.Rental
      }
      return TASK_TYPES.JobCost
    })

    const removingTaskId = ref<string | undefined>('');

    const datePickerStartDate = ref<any>(null);
    const selectTaskChangeType = ref<any>({
      taskType: "",
      taskId: "",
    });
    onBeforeMount(async () => {
      if (!props.isEdit) {
        // Create timesheet
        initTimesheetLine();
      }
    });

    const timesheetLineOptionInitData = {
      isExpend: true,
      type: defaultTaskHeaderType.value,
      hours: 0,
      mins: 0,
      visiblePopover: false,
      isValid: false,
      startTime: {
        hours: 0,
        isAm: true,
        minutes: 0,
        isInit: true
      },
      endTime: {
        hours: 0,
        isAm: true,
        minutes: 0,
        isInit: true
      }
    }

    const handelExpendTask = (taskId?: string) => {
      const task = timeSheetHeader.value.TimesheetLines?.find(
        (x) => x.TaskUuid === taskId
      );
      if (task) {
        task.Option!.isExpend = !task.Option?.isExpend;
      }
    };
    const handleSelectTabTask = () => {
      const task = timeSheetHeader.value.TimesheetLines?.find(
        (x) => x.TaskUuid === selectTaskChangeType.value.taskId
      );
      resetTaskData(selectTaskChangeType.value.taskId)
      if (task) {
        task.Option!.type = selectTaskChangeType.value.taskType;
        isTouched.value[selectTaskChangeType.value.taskId] = false
        isTouchedSwitch.value = false
      }
      calTotalTimeSheet();
      const isOverlap = funcOverLap()
      if (isOverlap) {
        ctx.emit('handlePopupOverLap', true)
      } else {
        ctx.emit('handlePopupOverLap', false)
      }
      const isOverlapLevel = funcOverLapLevel(timeSheetHeader);
      ctx.emit("handlePopupOverLapLevel", isOverlapLevel);
      popupConfirmChangeType.value.showDialog = false;
      ctx.emit('changeTouched', false)
      ctx.emit('handleCancelSubmit', false)
    };

    const calTotalTimeSheet = () => {
      let totalMinuets = 0;
      timeSheetHeader.value.TimesheetLines?.forEach((item) => {
        totalMinuets += item.TimesheetLineMinutes ?? 0;
      });
      totalHours.value = Math.floor(totalMinuets / 60);
      totalMins.value = totalMinuets % 60;
      ctx.emit('changeMaxTimeMultipleTasks', totalMinuets)
      const shiftCheck: any = funcFindMaxMinTime()
      if (shiftCheck.length > 0) {
        if (shiftCheck[0].dayShift) {
          ctx.emit('handleCheckDayShift', true)
        } else {
          ctx.emit('handleCheckDayShift', false)
        }
        if (shiftCheck[0].nightShift) {
          ctx.emit('handleCheckNightShift', true)
        } else {
          ctx.emit('handleCheckNightShift', false)
        }
      } else {
        ctx.emit('handleCheckDayShift', false)
        ctx.emit('handleCheckNightShift', false)
      }
    };

    const handleOpenPopupChangeTaskType = (
      taskType: string,
      taskId?: string
    ) => {
      const task = timeSheetHeader.value.TimesheetLines?.find(
        (x) => x.TaskUuid === taskId
      );
      if (task) {
        if (allowUpdate.value) {
          selectTaskChangeType.value.taskType = taskType;
          selectTaskChangeType.value.taskId = taskId;
          if (isTouched.value[taskId!]) {
            popupConfirmChangeType.value.showDialog = true;
          } else {
            handleSelectTabTask()
          }
        }
      }
    };

    const handleErrorSwitchTaskType = () => {
      popupError.value.showDialog = true
    }

    const addTask = () => {
      let maxEndTime = timeSheetHeader.value?.TimesheetLines.reduce((max, line) => (max?.EndTime ?? 0) > (line?.EndTime ?? 0) ? max : line, 0);
      const newTime = JSON.stringify(maxEndTime?.Option?.endTime)
      timeSheetHeader.value.TimesheetLines?.push({
        TaskUuid: uuidv4(),
        StartTime: (maxEndTime?.EndTime ?? 0),
        EndTime: (maxEndTime?.EndTime ?? 0),
        Option: {
          ...timesheetLineOptionInitData,
          name: `Task ${timeSheetHeader.value.TimesheetLines?.length + 1}`,
          startTime: JSON.parse(newTime) ?? timesheetLineOptionInitData.endTime,
        },
      });
      store.commit("dashboard/SET_IS_EDIT_ADD_TASK", true);
      
    };
    const removeTask = (taskId?: string) => {
      removingTaskId.value = taskId
      if (isTouched.value[taskId!]) {
        popupConfirmDelete.value.showDialog = true;
      } else {
        handleRemoveTask()
      }
    };
    const handleRemoveTask = () => {
      const indexTimeSheetLine =
        timeSheetHeader.value.TimesheetLines?.findIndex(
          (i) => i.TaskUuid == removingTaskId.value
        );
      if (indexTimeSheetLine !== undefined && indexTimeSheetLine > -1) {
        timeSheetHeader.value.TimesheetLines?.splice(indexTimeSheetLine, 1);
        removingTaskId.value = ''
        popupConfirmDelete.value.showDialog = false;
      }
      store.commit("dashboard/SET_IS_EDIT_ADD_TASK", false);
      calTotalTimeSheet()
      const isOverlap = funcOverLap()
      if (isOverlap) {
        ctx.emit('handlePopupOverLap', true)
      } else {
        ctx.emit('handlePopupOverLap', false)
      }
      const isOverlapLevel = funcOverLapLevel(timeSheetHeader);
      ctx.emit("handlePopupOverLapLevel", isOverlapLevel);
      ctx.emit('handleCancelSubmit', false)
    }

    const onChangeTouched = (value: any, taskId: string) => {
      isTouched.value[taskId] = value
      isTouchedSwitch.value = true
      ctx.emit('changeTouched', value)
    }


    const getStatusValidTimeSheet = () => {
      const findItemValidFail = timeSheetHeader.value.TimesheetLines?.find(
        (x) => {
         
          return x.Option?.isValid == false
        }
      );
      return findItemValidFail != undefined ? false : true;
    };

    const resetData = () => {
      timeSheetHeader.value = {
        TimesheetLines: [],
        StartDate: undefined,
        TimesheetStatus: 0,
      };
      if (!props.isEdit) {
        initTimesheetLine();
      }
      totalHours.value = 0;
      totalMins.value = 0;
    };    

    const datePickerfocus = () => {
      datePickerStartDate.value.handleOpen();
    };

    const onConfirmChangeShift = (type: string) => {
      if (allowUpdate.value) {
        if (isTouchedSwitch.value) {
          popupConfirmChangeShift.value.showDialog = true;
        } else {
          onChangeShift(type)
        }
      }
    }
   
    const onChangeShift = (type: string) => {
      if (type == 'Night') {
        ctx.emit('changeShift', true)
        timeSheetHeader.value.Shift = true  
      } else {
        ctx.emit('changeShift', false)
        timeSheetHeader.value.Shift = false  
      }
      popupConfirmChangeShift.value.showDialog = false;
      const shiftCheck: any = funcFindMaxMinTime()

      if (shiftCheck.length > 0) {
        if (shiftCheck[0].dayShift) {
          ctx.emit('handleCheckDayShift', true)
        } else {
          ctx.emit('handleCheckDayShift', false)
        }
        if (shiftCheck[0].nightShift) {
          ctx.emit('handleCheckNightShift', true)
        } else {
          ctx.emit('handleCheckNightShift', false)
        }
      } else {
        ctx.emit('handleCheckDayShift', false)
        ctx.emit('handleCheckNightShift', false)
      }
    }

    const onChangeValidTime = (messageError: string) => {
      messageValidTime.value = messageError
    }

    const handleCalendarChange = (value: any) => {
      const formatDate = dayjs(value).format("YYYY-MM-DD")
      store.dispatch("dashboard/loadCountTotalTimesheet", { date: formatDate})
    }

    watch([action, allTaskIsValid], (value) => {
      ctx.emit('handleAllTaskIsValid', allTaskIsValid.value);
      if (allTaskIsValid.value) {
        if (action.value == Actions.SAVE.toString()) {
          timeSheetHeader.value!.TimesheetStatus = 0;
          createTimeSheet(timeSheetHeader);
        }
        if (allFieldIsValid.value) {
          if (action.value == Actions.CONFIRM.toString()) {
            timeSheetHeader.value!.TimesheetStatus = 1;
            createTimeSheet(timeSheetHeader);
          }
        }
      } 
    })

    ctx.expose({
      loadTimeSheetEdit,
      getStatusValidTimeSheet,
      updateTimeSheet,
      resetData,
    });

    return {
      bgColorTaskType,
      handleSelectTabTask,
      currentTheme,
      handelExpendTask,
      IconCalendar,
      addTask,
      removeTask,
      handleTaskSubmit,
      handleSubmitTaskTime,
      totalHours,
      totalMins,
      updateTaskTime,
      timeSheetHeader,
      isEdit,
      isTouched,
      loadTimeSheetEdit,
      allowUpdate,
      currentUser,
      popupConfirmChangeType,
      popupConfirmDelete,
      popupError,
      selectTaskChangeType,
      handleOpenPopupChangeTaskType,
      handleRemoveTask,
      displayButtonAddTask,
      datePickerfocus,
      datePickerStartDate,
      onChangeShift,
      onChangeTouched,
      overlapTime,
      taskTypeDisplay,
      errors,
      handleErrorSwitchTaskType,
      onChangeValidTime,
      onConfirmChangeShift,
      popupConfirmChangeShift,
      handleCalendarChange
    };
  },
});
</script>
<style lang="scss">
.job-type {
  height: 36px;
  padding: 8px 32px;
}

.el-form-item__label {
  color: #231F20;
}

.dark {
  .detail-task {
    span {
      color: white;
    }
    .task-type-left,
    .task-type-middle,
    .task-type-right {
      span {
        color: #8D9095;
      }
    }
    .task-type-selected {
      span {
        color: #fff;
      }
    }
  }

  .btn-add-task {
    &:focus {
      background: #ED1C2D;
    }
  }

  .btn-add-task.is-disabled {
    opacity: 0.5;
  }
  .btn-add-task.is-disabled:hover {
    background: #ED1C2D;
    opacity: 0.5;
  }
  .el-form-item__label {
    color: white;
  }

  .el-button.btn-time-shift path {
    fill: white;
  }

  .el-button.btn-time-shift:disabled {
    color: #828282;
  }
}

.btn-add-task {
  width: 153px;
}

.btn-add-task {
  &:focus {
    background: #ED1C2D;
  }
}

.btn-add-task.is-disabled {
  background: #ED1C2D;
  opacity: 0.5;
}

.btn-add-task.is-disabled:hover {
  background: #ED1C2D;
  opacity: 0.5;
}

.btn-shift {
  width: 130px;
  border: none;
}

.rounded-group-button {
  border-radius: 6px;
  overflow: hidden;
}

.el-dialog-popup {
  .el-dialog__footer {
    padding-top: 24px;
    padding-bottom: 32px;
  }

  .el-dialog .el-dialog__footer .el-button.delete {
    margin-left: 16px;
  }

  .el-dialog .el-dialog__body {
    margin-top: 32px;
  }

  .el-dialog .el-dialog__header {
    margin-bottom: 0px !important;
  }
}
</style>