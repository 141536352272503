import { MutationTree } from "vuex";
import {
    TimeSheetTrackingState,
    FilterTimeSheetTracking,
    SET_LIST_TIME_SHEET_TRACKING,
    SET_FILTER_TIME_SHEET_TRACKING,
    SET_TIME_SHEET_TRACKING_COUNT,
    TimeSheetHistory,
    SET_LOADING_TIME_SHEET_TRACKING
} from "./types";

export const mutations: MutationTree<TimeSheetTrackingState> = {
    [SET_FILTER_TIME_SHEET_TRACKING](state: TimeSheetTrackingState, data: FilterTimeSheetTracking) {
        state.filterTimeSheetTracking = data;
    },
    [SET_LIST_TIME_SHEET_TRACKING](state: TimeSheetTrackingState, data: TimeSheetHistory[]) {
        state.timeSheetTrackings = data;
    },
    [SET_TIME_SHEET_TRACKING_COUNT](state: TimeSheetTrackingState, data: number) {
        state.timeSheetTrackingCount = data;
    },
    [SET_LOADING_TIME_SHEET_TRACKING](state: TimeSheetTrackingState, status: boolean) {
        state.loading = status;
    },

};
