<template>
  <!-- <el-button ref="buttonRef"
    >Click me</el-button
  > -->
  <div class="flex items-center justify-center mt-[20px] cursor-pointer" :class="disable ? 'clock-disable' : ''" ref="buttonRef">
    <img src="../assets/icon_clock.svg" height="32" width="32" />
    <span style="
              font-weight: 600;
              font-size: 36px;
              margin-left: 14px;
              margin-right: 14px;
            " :style="{ opacity: orgHours != 0 || orgMins != 0 ? 1 : 0.4 }">{{
              `${orgHours >= 10 ? orgHours : "0" + orgHours}h:${orgMins >= 10 ? orgMins : "0" + orgMins
              }m`
            }}</span>
    <el-icon size="16px">
      <ArrowDown />
    </el-icon>
  </div>
  <div class="font-medium flex items-center justify-center mb-[20px]">
    <span class="text-center break-normal" style="color: #ed1c2d" v-if="errorMessage && !isValid">{{errorMessage}}</span>
    <span class="text-[#ed1c2d] text-center break-normal" v-if="msgExceedingTime.length != 0 && !errorMessage && !isValid">{{ msgExceedingTime }}</span>
    <span style="color: #ed1c2d" v-if="isValid">Please choose working time</span>
  </div>
  <el-popover ref="popoverRef" @before-leave="handleBeforeLeave" :virtual-ref="buttonRef" trigger="click" :width="220"
    :disabled="disable" virtual-triggering>
    <div class="p-[8px]">
      <div class="grid grid-flow-row auto-rows-max">
        <div class="font-medium text-sm mb-[8px] lable-popup-time">Enter Time</div>
        <div class="grid grid-flow-col auto-cols-max">
          <div class="mr-[8px]">
            <div class="flex flex-col">
              <div>
                <input class="
                          h-[64px]
                          w-[64px]
                          font-normal
                          text-center
                          time-picker-component-input" 
                  type="text"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');if(Number(this.value)>23) this.value=23;" 
                  onclick="this.select()"
                  style="
                    font-size: 36px;
                    border: 1px solid #246bfd;
                    border-radius: 6px;
                    "
                  :value="hoursTmp" @change="handleUpdateStartTime($event, true)" />
              </div>
              <div class="mt-[6px] text-sm font-semibold lable-popup-time">
                <span>Hours</span>
              </div>
            </div>
          </div>
          <p class="font-normal text-center lable-popup-time" style="font-size: 36px">:</p>
          <div class="ml-[8px]">
            <div class="flex flex-col">
              <div>
                <input class="
                          h-[64px]
                          w-[64px]
                          font-normal
                          text-center
                          time-picker-component-input
                        " type="text"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');if(Number(this.value)>59) this.value=59;"
                  onclick="this.select()"
                  style="font-size: 36px; border: 1px solid #246bfd; border-radius: 6px;" :value="minutesTmp"
                  @change="handleUpdateStartTime($event, false)" />
              </div>
              <div class="mt-[6px] text-sm font-semibold lable-popup-time">
                <span>Minute</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center; margin: 0px; padding-bottom: 8px; padding-top: 12px;">
      <el-button class="btn-cancel" @click="handleCancel">Cancel</el-button>
      <el-button type="primary" class="btn-apply" style="background-color: #246bfd"
        @click="handleSubmitTaskTime">Apply</el-button>
    </div>
  </el-popover>
</template>
<script lang="ts">
import {computed, defineComponent, onMounted, ref, unref, watch} from "vue";
import { ArrowDown } from "@element-plus/icons-vue";
import { ClickOutside as vClickOutside } from "element-plus";
import { useStore } from "vuex";
export default defineComponent({
  name: "StartTimeSheet",
  props: {
    hours: Number,
    minutes: Number,
    taskId: String,
    disable: Boolean,
    errorMessage: String,
    totalHour: Number,
  },
  components: {
    ArrowDown,
  },
  setup(props, ctx) {
    const orgHours = ref<number>(props.hours ?? 0);
    const orgMins = ref<number>(props.minutes ?? 0);
    const hoursTmp = ref<number>(props.hours ?? 0);
    const errorMessage = ref<string | undefined>(props.errorMessage);
    const minutesTmp = ref<number>(props.minutes ?? 0);
    const buttonRef = ref();
    const popoverRef = ref();
    const taskId = ref<string>(props.taskId ?? "");
    const isValid = ref<boolean>(false);
    const disable = ref<boolean>(props.disable ?? false);
    const msgExceedingTime = ref<string>('');

    const onClickOutside = () => {
      unref(popoverRef).popperRef?.delayHide?.();
    };

    watch(props, () => {
      hoursTmp.value = props?.hours ?? 0
      orgHours.value = props?.hours ?? 0
      minutesTmp.value = props?.minutes ?? 0
      orgMins.value = props?.minutes ?? 0
      errorMessage.value = props?.errorMessage
    });

    const handleSubmitTaskTime = () => {
      validData()
      orgHours.value = hoursTmp.value
      orgMins.value = minutesTmp.value
      ctx.emit("submit", {
        taskId,
        hours: hoursTmp.value,
        minutes: minutesTmp.value,
      });
      popoverRef.value.hide();
    };
    const handleCancel = () => {
      popoverRef.value.hide();
    };

    const handleUpdateStartTime = (event: any, isUpdateHours: boolean) => {
      if (isUpdateHours) {
        hoursTmp.value = parseInt(event.target.value) || 0;
      } else {
        let mins = parseInt(event.target.value) || 0;
        if (mins > 60) {
          hoursTmp.value += Math.floor(mins / 60);
          mins = mins % 60;
        }
        minutesTmp.value = mins;
      }
    };

    const validData = () => {
      isValid.value = false;
      if (hoursTmp.value <= 0 && minutesTmp.value <= 0) {
        isValid.value = true;
        return true;
      }
      return false;
    };

    const handleBeforeLeave = () => {
      hoursTmp.value = orgHours.value;
      minutesTmp.value = orgMins.value;
    }

    ctx.expose({
      validData,
    });

    return {
      buttonRef,
      popoverRef,
      onClickOutside,
      handleSubmitTaskTime,
      handleCancel,
      handleUpdateStartTime,
      isValid,
      validData,
      disable,
      hoursTmp,
      minutesTmp,
      orgHours,
      orgMins,
      handleBeforeLeave,
      errorMessage,
      msgExceedingTime
    };
  },
});
</script>
<style scoped lang="scss">
.btn-apply{
  border: none;
  padding: 10px 20px;
  &:hover, &:focus {
    color: white !important;
  }
}
.btn-cancel {
  padding: 10px 20px;
}

.clock-disable {
  cursor: text !important;
}
</style>