import { MutationTree } from "vuex";
import {
  DashboardState,
  SET_DROPDOWN_SITE,
  SET_ACTION_TIME_SHEET,
  SET_LIST_TIME_SHEET,
  SET_COUNT_TIME_SHEET_STATUS,
  SET_FILTER_TIME_SHEET,
  CountTimeSheetStatus,
  FilterTimeSheet,
  Actions, SET_LIST_TIME_SHEET_EVENT, SET_COUNT_NOTI_UNREAD,
  SET_LOADING_NOTI,
  SET_IS_EDIT_ADD_TASK,
  SET_TOTAL_HOUR_TIME_SHEET,
  SET_LIST_TIME_SHEET_LINE
} from "./types";

export const mutations: MutationTree<DashboardState> = {
  [SET_DROPDOWN_SITE](state: DashboardState, data: Equipment[]) {
    state.dropdownSite = data;
  },
  [SET_ACTION_TIME_SHEET](state: DashboardState, data: Actions) {
    state.action = data;
  },
  [SET_LIST_TIME_SHEET](state: DashboardState, data: TimeSheetHeader[]) {
    state.timeSheets = data;
  },
  [SET_COUNT_TIME_SHEET_STATUS](state: DashboardState, data: CountTimeSheetStatus) {
    state.countTimeSheetStatus = data;
  },
  [SET_FILTER_TIME_SHEET](state: DashboardState, data: FilterTimeSheet) {
    state.filterTimeSheet = data;
  },
  [SET_LIST_TIME_SHEET_EVENT](state: DashboardState, data: any) {
    state.timeSheetEvents = data;
  },
  [SET_COUNT_NOTI_UNREAD](state: DashboardState, data: any) {
    state.countNotiUnRead = data;
  },
  [SET_LOADING_NOTI](state: DashboardState, status: boolean) {
    state.loading = status;
  },
  [SET_IS_EDIT_ADD_TASK](state: DashboardState, status: boolean) {
    state.isEditAddTask = status;
  },
  [SET_TOTAL_HOUR_TIME_SHEET](state: DashboardState, data: number) {
    state.totalHour = data;
  },
  [SET_LIST_TIME_SHEET_LINE](state: DashboardState, data: any) {
    state.timeSheetsLine = data;
  },
};
