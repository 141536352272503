<template>
  <DefaultLayoutAuth v-if="isAuthenticated === false" />
  <DefaultLayout v-else />
</template>

<script lang="ts">
import { computed, defineComponent, provide, watch } from 'vue'
import { useStore } from 'vuex'
import DefaultLayout from './layouts/default-layout.vue'
import DefaultLayoutAuth from './layouts/default-layout-auth.vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElLoading  } from 'element-plus'

export default defineComponent({
  name: 'App',

  components: {
    DefaultLayout,
    DefaultLayoutAuth,
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const loading = computed(() => store.state.loading)
    const initialize = () => {
      store.commit('SET_LOADING', true)
      return Promise.resolve()
    }
    const isAuthenticated = computed(() => {
      if (["/login", "/forget-password", "/reset-password", "/register"].includes(router.currentRoute.value.path)) {
        return false
      }
      return true
    })

    watch(loading, () => {
      // const elm = document.getElementById('global-loading')
      // if (loading.value) {
      //   elm?.classList.remove('hidden')
      // } else {
      //   elm?.classList.add('hidden')
      // }
      
    })
    initialize()
      .then(() => store.dispatch('initialized', true))
      .finally(() => store.commit('SET_LOADING', false))

    // Notify
    const notify = (type: string, message: string) => {
      let toast = null;
      switch(type){
        case 'error':
          toast = ElMessage.error(message);
          break;
        case 'success':
          toast = ElMessage.success(message);
          break;
        case 'warning':
          toast = ElMessage.warning(message);
          break;
        case 'info':
          toast = ElMessage.info(message);
          break;
        default:
          // ElMessage(message);
          break;
      }
      return toast;
    }

    provide('notify', notify);
    
    return {
      isAuthenticated,
    }
  },
})
</script>
<style lang="scss">
@import '@/styles';
</style>
