import { MutationTree } from "vuex";
import { ApprovalGroupState, } from "./types";

export const mutations: MutationTree<ApprovalGroupState> = {
    SET_LIST_APPROVAL: (state: ApprovalGroupState, lsGroupApproval: ApprovalGroupModel[]) => {
        state.lsGroupApproval = lsGroupApproval;
    },
    SET_COUNT_APPROVAL: (state: ApprovalGroupState, countGroupApproval: number) => {
        state.countGroupApproval = countGroupApproval;
    },
    SET_LOADING_GROUP: (state: ApprovalGroupState, status: boolean) => {
        state.loading = status
    },
};
