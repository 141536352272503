import { RootState } from "store";
import { ActionTree } from "vuex";
import { ManualService } from "../service";
import { ManualState } from "./types";

const service = new ManualService()

export const actions: ActionTree<ManualState, RootState> = {
  
  async insert({ commit }, params: any, options?: any) {

    const config = {
      onUploadProgress: function(progressEvent: any) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if(percentCompleted < 100 ){
          commit('SET_PERCENT_UPLOAD', percentCompleted);

        }
      }
    }

    if(params){
      const data = await service.insert(params, config).then(((res) => {
        commit('SET_PERCENT_UPLOAD', 100);
      }));
      return data;
    }
  },
  async update ({ commit }, params: any) {
    if(params){
      const data = await service.update(params, params.ManualId);
      return data;
    }
},

  async select({ commit }, params: any) {
    const filter = null;
    const active = params?.active
    const selectProps = 'ManualId, Name, Description, Active, VisibleTypes';
   
    const data = await service.select(selectProps, filter, active);
    return data.value.map((item:any) => {
      return {
        ...item,
      }
    });
  },
  async selectById({ commit }, id: any) {
    let data: any;
    data = await service.selectById('ManualId, Name, Description, Active, VisibleTypes', id);
    return data;
  },

  async deleteManual({ commit }, params: any) {
    if(params){
      const data = await service.deleteManual(params);
      return data;
    }
  },

  async downloadFileById({ commit }, id: any) {
    let data: any;
    data = await service.downloadFileById(id);
    return data;
  },
  
  
};
