import { USER_TYPE_APPROVER, USER_TYPE_STAFF, USER_TYPE_SYSTEM_ADMINISTRATOR, USER_TYPE_USER_ADMINISTRATOR } from 'core/constants';

const LoginView = () => import('modules/auth/views/login.vue');
const DashboardView = () => import('modules/dashboard/views/dashboard.vue');

const DashboardListView = () =>
  import('modules/super/dashboard/views/dashboard.vue');
  
const DashboardSuperEditView = () =>
  import('modules/super/dashboard/views/edit.vue');
  
const ManualView = () => import('modules/manual/views/manual.vue');
const ProfileView = () => import('modules/profile/views/profile.vue');
const NotificationsView = () =>
  import('modules/notifications/views/notificationv2.vue');
const ForgetPasswordView = () =>
  import('modules/auth/views/forget-password.vue');
const ResetPasswordView = () => import('modules/auth/views/reset-password.vue');
const RegisterView = () => import('modules/auth/views/register.vue');
const NotificationsSuperView = () =>
  import('modules/notifications/views/notificationv2.vue');
const UsersView = () =>
  import('modules/admin/users/views/users.vue');
const ApprovalGroupsView = () =>
  import('modules/admin/groups/views/approval-groups.vue');
const AccessDeniedView = () =>
  import('modules/errors/views/access-denied.vue');
const TimesheetTrackingListView = () =>
  import('modules/super/timesheetTrackings/views/timesheetTracking.vue');
const SettingsView = () =>
  import('modules/settings/views/settings.vue');
const UserTrackingsView = () => import('modules/super/userTrackings/views/userTrackings.vue');

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: 'dashboard'
  },
  {
    path: '/access-denied',
    name: 'Access Denied',
    meta: {
      requiresAuth: true,
    },
    component: AccessDeniedView
  },
  {
    path: '/callback',
    name: 'Callback',
    meta: {
      requiresAuth: false,
    },
    redirect: 'dashboard'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: ForgetPasswordView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPasswordView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/dashboard',
    component: DashboardView,
    name: 'Dashboard',
    meta: {
      //   title: 'header.dashboard',
      requiresAuth: true,
      roles: [USER_TYPE_STAFF]
    },
  },
  {
    path: '/super/dashboard',
    component: DashboardListView,
    name: 'DashboardSuper',
    meta: {
      //   title: 'header.dashboard',
      requiresAuth: true,
      roles:[USER_TYPE_APPROVER, USER_TYPE_SYSTEM_ADMINISTRATOR, USER_TYPE_USER_ADMINISTRATOR]
    },
    alias: ['/admin/dashboard']
  },
  {
    path: '/super/user-trackings',
    component: UserTrackingsView,
    name: 'UserTrackings',
    meta: {
      requiresAuth: true,
      roles:[USER_TYPE_SYSTEM_ADMINISTRATOR]
    },
    alias: ['/admin/user-trackings']
  },
  {
    path: '/super/timesheet-trackings',
    component: TimesheetTrackingListView,
    name: 'TimesheetTracking',
    meta: {
      //   title: 'header.dashboard',
      requiresAuth: true,
      roles:[USER_TYPE_SYSTEM_ADMINISTRATOR, USER_TYPE_USER_ADMINISTRATOR]
    },
    alias: ['/admin/timesheet-trackings']
  },
  {
    path: '/super/dashboard/edit/:id',
    component: DashboardSuperEditView,
    name: 'DashboardSuperEdit',
    meta: {
      //   title: 'header.dashboard',
      requiresAuth: true,
      roles:[USER_TYPE_APPROVER, USER_TYPE_SYSTEM_ADMINISTRATOR]
    },
  },
  {
    path: '/manuals',
    component: ManualView,
    name: 'Manuals',
    meta: {
      //   title: 'header.dashboard',
      requiresAuth: true,
      roles:[USER_TYPE_APPROVER, USER_TYPE_STAFF, USER_TYPE_SYSTEM_ADMINISTRATOR, USER_TYPE_USER_ADMINISTRATOR]
    },
  },
  {
    path: '/profile',
    component: ProfileView,
    name: 'Profile',
    meta: {
      //   title: 'header.dashboard',
      requiresAuth: true,
      roles:[USER_TYPE_APPROVER, USER_TYPE_STAFF, USER_TYPE_SYSTEM_ADMINISTRATOR, USER_TYPE_USER_ADMINISTRATOR]
    },
  },
  {
    path: '/notifications',
    component: NotificationsView,
    name: 'Notifications',
    meta: {
      //   title: 'header.dashboard',
      requiresAuth: true,
      roles:[USER_TYPE_APPROVER, USER_TYPE_STAFF, USER_TYPE_SYSTEM_ADMINISTRATOR]
    },
  },
  {
    path: '/super/notifications',
    component: NotificationsSuperView,
    name: 'NotificationsSuper',
    meta: {
      //   title: 'header.dashboard',
      requiresAuth: true,
      roles:[USER_TYPE_APPROVER, USER_TYPE_SYSTEM_ADMINISTRATOR]
    },
  },
  // {
  //   path: '/admin/dashboard',
  //   component: DashboardListView,
  //   name: 'DashboardSuper',
  //   meta: {
  //     requiresAuth: false,
  //     roles:[USER_TYPE_SYSTEM_ADMINISTRATOR]
  //   },
  // },
  {
    path: '/admin/users',
    component: UsersView,
    name: 'Users',
    meta: {
      //   title: 'header.dashboard',
      requiresAuth: true,
      roles:[USER_TYPE_SYSTEM_ADMINISTRATOR, USER_TYPE_USER_ADMINISTRATOR]
    },
  },
  {
    path: '/admin/approval-groups',
    component: ApprovalGroupsView,
    name: 'ApprovalGroups',
    meta: {
      //   title: 'header.dashboard',
      requiresAuth: true,
      roles:[USER_TYPE_SYSTEM_ADMINISTRATOR, USER_TYPE_USER_ADMINISTRATOR]
    },
    // props: { isAdmin: false },
  },
  {
    path: '/admin/settings',
    component: SettingsView,
    name: 'Settings',
    meta: {
      requiresAuth: true,
      roles:[USER_TYPE_SYSTEM_ADMINISTRATOR]
    },
  },
];

export default routes;
